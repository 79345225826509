<template>

  <div class="container mt-4 mb-4 w-75">
    <div class="row justify-content-center">
      <div class="card w-100 shadow-sm h-100">
        <div class="card-body h-100">
          <h4 class="card-title">Cyfrowe Centrum Serwisowe S.A.</h4>
          <hr class="hr-ccs">
          <p class="fs-4 text-center mt-5 mb-5 text-ccs">404&nbsp;|&nbsp;NOT FOUND</p>
          <p class="fs-5 text-center">Przykro nam, strona o podanym adresie nie istnieje.</p>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "404"
}
</script>

<style scoped>

</style>