<template>

  <div id="wz1-step-two" class="h-100 animate__animated animate__fadeInRight">

    <div id="order-number-section" class="row align-items-center">
      <div class="col-xxl">
        <div class="card ccs-card-radius overflow-hidden border-4 w-100 shadow-sm h-100" style="min-height: 425px;">
          <div class="card-body">
            <h4 class="card-title">{{ $t('wz_case_form.wz1_step_two.title') }}</h4>
            <hr class="hr-ccs">
            <div class="row mb-5 mt-3">
              <div class="col-md-2"></div>
              <div class="col-md-8">
                <div v-if="v_error" class="alert alert-danger" role="alert">
                  {{ v_validation_error }}
                </div>
                <div v-else class="alert">
                  &nbsp;
                </div>
                <label for="order-number" class="form-label">{{ $t('wz_case_form.wz1_step_two.order_number') }}</label>
                <input type="text" class="form-control"
                       :class="{'is-invalid':v_error, 'is-valid':v_is_valid}"
                       id="order-number" v-model="order_number"
                       @focus="v_error = false;" @change="changeOrderNumber">
                <div id="order-number-help" class="form-text">
                  {{ $t('wz_case_form.wz1_step_two.description') }}
                </div>
                <div class="col-md-2"></div>
              </div>
            </div>
          </div>

          <div class="card-footer bg-ccs-light">
            <div class="d-flex justify-content-around flex-wrap-reverse">
              <div class="p-2">
                <button class="btn btn-outline-ccs min-w-200 mt-2 mb-2" @click="backToPrevStep">{{ $t('wz_case_form.btn_back') }}</button>
              </div>
              <div class="p-2">
                <button class="btn btn-ccs min-w-200 mt-2 mb-2" @click="submitStepTwo">{{ $t('wz_case_form.btn_accept') }}</button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {Wz1FormValidator} from "@/mixins/Wz1FormValidator";

export default {
  name: "StepTwo",
  mixins: [Wz1FormValidator],
  computed: {
    ...mapGetters('WzCase', {wz_case: 'wz_case'}),
    ...mapGetters('Wz1From', {wz1_form: 'wz1_form'}),
  },
  data() {
    return {
      order_number: null,
    }
  },
  methods: {
    ...mapActions('Wz1From', {updateWz1Form: 'updateWz1Form'}),
    changeOrderNumber: function () {
      if (this.v_validateOrderNumber(this.order_number)) {
        this.updateWz1Form({
          key: 'order_number',
          value: this.order_number
        });
      }
    },
    backToPrevStep: function () {
      document.getElementById("wz1-step-two").classList = "h-100 animate__animated animate__fadeOutRight";
      setTimeout((function () {
        this.$emit('backToPrevStep');
      }).bind(this), 600);
    },
    submitStepTwo: function () {
      /** ORDER NUMBER VALIDATION */
      if (this.v_validateOrderNumber(this.order_number)) {
        document.getElementById("wz1-step-two").classList = "h-100 animate__animated animate__fadeOutLeft";
        setTimeout((function () {
          this.$emit('submitStepTwo');
        }).bind(this), 600);
      }
    },
  },
  mounted() {
    this.order_number = this.wz1_form.order_number;
  }
}
</script>

<style scoped>

.min-w-200 {
  min-width: 200px;
}

</style>