<template>

  <div id="damages-gallery" class="h-100">
    <div class="card ccs-card-radius w-100 h-100 shadow-sm ">
      <div class="card-body h-100">
        <h4 class="card-title">{{ $t('wz_case_form.damages_gallery.title') }}</h4>
        <hr class="hr-ccs">
        <div id="wzGallery" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button v-for="(attachment, index) in attachments" type="button" data-bs-target="#wzGallery"
                    :data-bs-slide-to="index"
                    :class="{ 'active': index === 0 }"
                    :aria-current="{ 'true': index === 0 }"
                    :aria-label="attachment.file_name"
                    :key="index"></button>
          </div>
          <div class="carousel-inner">
            <div v-for="(attachment, index) in attachments" class="carousel-item"
                 :class="{ 'active': index === 0 }" :key="index">
              <a target="_blank"
                 :href="attachment_base_url + '/attachment/' + wz_case.case_guid + '/' + wz_case.service_history_id + '/' + attachment.id">
                <img
                    :src="attachment_base_url + '/attachment/' + wz_case.case_guid + '/' + wz_case.service_history_id + '/' + attachment.id"
                    class="d-block w-100" :alt="attachment.file_name">
              </a>
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#wzGallery"
                  data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#wzGallery"
                  data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "DamagesGallery",
  computed: {
    ...mapGetters('WzCase', {wz_case: 'wz_case', attachments: 'attachments'}),
  },
  data() {
    return {
      attachment_base_url: process.env.VUE_APP_BACKEND_BASE_URL
    }
  }
}
</script>

<style scoped>

</style>