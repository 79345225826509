<template>
  <div>

    <div v-if="errored" class="container mt-4">
      <app-error/>
    </div>

    <app-loader v-if="loading"/>

    <div class="container mt-4 mb-2">
      <app-wz-question v-for="(question, index) in questions" :key="index" :question="question"/>
      <div class="row">
        <div>
          <button class="btn btn-ccs float-end" type="button" @click="markAnswersAsFinal(questions)"
                  style="width: 150px">
            {{ $t('questionnaire.btn_next') }}
          </button>
        </div>
      </div>
      <div class="row" style="height: 30px">
        &nbsp;
      </div>
      <div>
        <button class="btn btn-link min-w-200 float-end" type="button" @click="skipQuestionnaire()">
          {{ $t('questionnaire.btn_skip') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {mapGetters} from "vuex";

export default {
  name: "WzQuestionnaire",
  emits: ['go-next', 'ready-to-ask', 'nothing-to-ask'],
  watch: {
    questions: {
      handler(val) {
        if ((!this.allow_sending_answers && this.haveAnyAnswers(this.questions))
            || (this.questions && this.questions.isFinal)) {
          this.allow_sending_answers = true;
        }
        this.sendAnswers(val);
      },
      deep: true
    }
  },

  methods: {
    fetchQuestions: function () {
      let context = this;
      axios.get(this.questionnaire_url + "/" + this.case_guid + "/" + this.wz_type_no)
          .then((response) => {
            this.questions = response.data;
            if (!Array.isArray(this.questions)) {
              this.emitNothingToAsk();
              this.goNextStep();
            } else {
              this.emitReadyToAsk();
            }

          })
          .catch(() => {
            context.emitNothingToAsk();
            context.goNextStep();
          })
          .finally(() => this.loading = false);
    },
    emitNothingToAsk: function () {
      this.$emit("nothing-to-ask");
    },
    emitReadyToAsk: function () {
      this.$emit("ready-to-ask");
    },
    sendAnswers: function (questionsObj) {
      if (!this.allow_sending_answers) {
        return;
      }
      if (questionsObj.isFinal) {
        return;
      }

      let postQuestions = {
        data: questionsObj,
        isFinal: false
      }
      axios.post(this.questionnaire_url + "/" + this.case_guid, postQuestions)
          .catch(() => {
            console.log("Answers not updated - technical error.");
          });
    },

    markAnswersAsFinal: function (questionsObj) {
      if (!this.allow_sending_answers) {
        this.goNextStep();
        return;
      }
      questionsObj.isFinal = true;
      this.sending_questionnaire = true;
      this.loading = true;
      let postQuestions = {
        data: questionsObj,
        isFinal: true
      }
      axios.post(this.questionnaire_url + "/" + this.case_guid, postQuestions)
          .catch(() => {
            console.log("Answers not stored - technical error");
          })
          .finally(() => {
            this.sending_questionnaire = false;
            this.loading = false;
            this.goNextStep();
          })

    },
    skipQuestionnaire: function () {
      this.goNextStep();
    },

    goNextStep: function () {
      this.$emit("go-next");
    },

    haveAnyAnswers: function (questionsObj) {
      if (!questionsObj) {
        return false;
      }

      let haveElements = false;

      questionsObj.forEach((question) => {
        if (question.selectedAnswers !== undefined
            && Array.isArray(question.selectedAnswers)
            && question.selectedAnswers.length > 0) {
          haveElements = true;
        }

        if (question.selectedAnswers !== undefined
            && !Array.isArray(question.selectedAnswers)
            && question.selectedAnswers) {
          haveElements = true;
        }
      });
      return haveElements;
    }
  },

  data() {
    return {
      questionnaire_url: process.env.VUE_APP_QUESTIONNAIRE_URL,
      questions: null,
      sending_questionnaire: false,
      loading: false,
      errored: false,
      allow_sending_answers: false,
    }
  },
  computed: {
    ...mapGetters('WzCase', {wz_case: 'wz_case'}),
    case_guid() {
      if (this.wz_case && this.wz_case.case_guid) {
        return this.wz_case.case_guid;
      }
      return this.$route.params.case_guid
    },
    wz_type_no() {
      if (this.wz_case && this.wz_case.wz_number) {
        return this.wz_case.wz_number.substring(2);
      }
      return this.$route.params.wz_type_no
    }
  },

  mounted() {
    this.fetchQuestions();
  },

}
</script>

<style scoped>

</style>