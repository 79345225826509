<template>
  <div id="questionnaire" v-if="questionnaire_visibility" :style="{display: questionnaire_display_mode}">
    <div class="container mt-xxl-5 mb-4 h-100">
      <app-wz-questionnaire v-on:go-next="showWzForm()" v-on:nothing-to-ask="showWzForm()"
                            v-on:ready-to-ask="questionnaireFormIsReady()"/>
    </div>
  </div>

  <div id="wz1-form" :style="{display: wz_display_mode}">
    <div class="container-fluid mt-4 mb-2">

      <div class="row">
        <div class="col">
          <h1 class="text-center text-decoration-underline text-uppercase fs-2-ccs-special">
            {{ $t('wz_case_form.title') }}
          </h1>
        </div>
      </div>

      <div class="row">

<!--        left column-->
        <div class="col-12 col-xxl-3 mt-2">
          <div class="row g-2 card-group">
            <div class="col-12 col-md-6 col-xxl-12">
              <app-service-order-data/>
            </div>
            <div class="col-12 col-md-6 col-xxl-12" v-if="attachments.length !== 0">
              <app-damages-gallery/>
            </div>
          </div>
        </div>

<!--        middle column -->
        <div v-if="payment_transaction.length === 0"  class="col col-xxl-6 col-lg-12 order-xxl-2 order-md-3 mt-2">

          <div v-if="wz_case.active">

            <div v-if="step === 1" class="mt-0 mb-2 p-0">
              <app-wz1-details/>


              <div class="row g-2 card-group d-flex d-md-none">
                <div class="col-12 col-md-6 col-xxl-12" v-if="wz_case.active">
                  <app-decision-time-counter :timer="timer" :version="'mobile'"/>
                </div>
                <div class="col-12 col-md-6 col-xxl-12" >
                  <app-promo-box/>
                </div>
              </div>

              <app-wz1-step-one-70-77 v-if="wz_case.fax_id === 70 || wz_case.fax_id === 77"
                                      v-on:setDecision="selectDecision($event)"/>
              <app-wz1-step-one-86-87 v-else-if="wz_case.fax_id === 86 || wz_case.fax_id === 87"
                                      v-on:setDecision="selectDecision($event)"/>
              <app-wz1-step-one-general v-else v-on:setDecision="selectDecision($event)"/>

            </div>
            <div v-else style="min-height: 550px;">
              <div class="row align-items-center mb-2">
                <div class="col-xxl">
                  <div class="card ccs-card-radius overflow-hidden w-100 shadow-sm h-100">
                    <div class="card-header bg-ccs text-white text-center">
                      <h4 class="card-title mt-0 mb-0" v-if="wz1_form.decision == 'no'">
                        {{ $t('wz_case_form.decision_form.answ_no.title') }}</h4>
                      <h4 class="card-title mt-0 mb-0" v-if="wz1_form.decision == 'yes'">
                        {{ $t('wz_case_form.decision_form.answ_yes.title') }}</h4>
                      <h4 class="card-title mt-0 mb-0" v-if="wz1_form.decision == 'util'">
                        {{ $t('wz_case_form.decision_form.answ_util.title') }}</h4>
                    </div>
                  </div>
                </div>
              </div>
              <app-wz1-step-two v-if="step === 2 && wz_case.require_order_number"
                                v-on:backToPrevStep="backToPrevStep"
                                v-on:submitStepTwo="submitStep(2)"/>
              <app-wz1-step-three v-if="step === 3"
                                  v-on:backToPrevStep="backToPrevStep"
                                  v-on:submitStepThree="submitStep(3)"/>
              <div id="step-4" v-if="step === 4">
                <app-process-loader id="step-5-loader" v-if="process_loading"
                                    :message="$t('process_loader.process_decision')"/>
                <div v-else>
                  <app-error v-if="process_error"/>
                  <app-wz1-step-four v-else
                                     v-on:backToPrevStep="backToPrevStep"
                                     v-on:submitStepFour="submitStep(4)"/>
                </div>
              </div>
              <div id="step-5" v-if="step === 5">
                <app-error v-if="process_error"/>
                <app-wz1-step-five v-else/>
              </div>
            </div>
          </div>
          <app-wz1-step-five v-else/>
        </div>
        <div v-else class="col col-xxl-6 col-lg-12 order-xxl-2 order-md-3 mt-2">
          <app-payment-rejected v-if="payment_transaction.status === 'REJECTED'"/>
          <app-payment-finished
              v-else-if="payment_transaction.status === 'VERIFIED CCS' || payment_transaction.status === 'ACCEPTED' || payment_transaction.status === 'READY_FOR_AX'"/>
          <app-payment-in-progress v-else/>
        </div>

<!--        right column-->
        <div class="col-12 col-xxl-3 order-xxl-3 order-md-2 mt-2 d-none d-md-block" >
          <div class="row g-2 card-group">
            <div class="col-12 col-md-6 col-xxl-12" v-if="wz_case.active">
              <app-decision-time-counter :timer="timer" :version="'large'"/>
            </div>
            <div class="col-12 col-md-6 col-xxl-12" >
              <app-promo-box/>
            </div>
          </div>
        </div>

      </div>
    </div>


  </div>

</template>

<script>

import {Wz1FormProcess} from "@/mixins/Wz1FormProcess";

export default {
  name: "Wz1Form",
  data() {
    return {
      /** refers to decision no questionnaire */
      wz_display_mode: "block",
      questionnaire_visibility: false,
      questionnaire_showed: false,
      questionnaire_display_mode: "none",
    }
  },

  mixins: [Wz1FormProcess],

  methods: {
    /** Questionnaire */
    hideWzForm: function () {
      this.wz_display_mode = "none";
    },
    hideQuestionnaireForm: function () {
      this.questionnaire_visibility = false;
    },
    displayQuestionnaireForm: function () {
      this.questionnaire_visibility = true;
    },
    displayWzForm: function () {
      this.wz_display_mode = "block";
    },
    showWzForm: function () {
      if (this.questionnaire_display_mode === "block") {
        this.hideQuestionnaireForm();
        this.questionnaire_display_mode = "none";
        this.displayWzForm();
      }
    },
    showQuestionnaireForm: function () {
      this.displayQuestionnaireForm();
      this.questionnaire_showed = true;
    },
    questionnaireFormIsReady: function () {
      this.hideWzForm();
      this.questionnaire_display_mode = "block";
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    /** Set WzCase form case_guid */
    this.updateWz1Form({
      key: 'case_guid',
      value: this.wz_case.case_guid
    })
    /** Set WzCase form case_guid */
    this.updateWz1Form({
      key: 'ip',
      value: this.user_ip
    })
  }
}
</script>

<style scoped>
.fs-2-ccs-special {
  font-size: 1.7rem!important;
}
</style>