<template>
  <div class="modal" ref="modal" id="modal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content border-4 border-ccs ccs-card-radius p-3">
        <div class="modal-body">
          <p class="mb-0"><b>{{ message }}</b></p>
        </div>
        <div class="modal-footer pb-0">
          <button type="button" class="btn btn-outline-ccs btn-ccs" data-bs-dismiss="modal" @click="confirm()">
            {{ $t('wz_case_form.decision_form.button_confirm') }}
          </button>
          <button type="button" class="btn btn-outline-ccs" data-bs-dismiss="modal">
            {{ $t('wz_case_form.decision_form.button_return') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {Modal} from "bootstrap";

export default {
  name: "ConfirmationModal",
  props: {
    message: String
  },
  mounted() {
    this.modal = new Modal(this.$refs.modal);
  },
  methods: {
    show: function () {
      this.modal.show();
    },
    confirm: function () {
      this.$emit('confirm');
    }
  }
}
</script>

<style scoped>

</style>