<template>

  <div id="damages-gallery" class="h-100">
    <div class="card ccs-card-radius w-100 h-100 shadow-sm ">
      <div class="card-body h-100">
        <h4 class="card-title" style="text-indent: -5px;">&nbsp;{{ $t('wz_case_form.promo.title') }}&nbsp;&nbsp;</h4>
        <hr class="hr-ccs">
        <div class="px-2 mb-2 font-weight-bold">
          <p class="m-0 check-icon-indent"><app-icon-check size="25" icon_color="#0c6f5d" class="me-1"/>
            <strong>{{ $t('wz_case_form.promo.list_1_a') }}</strong> {{ $t('wz_case_form.promo.list_1_b') }}
          </p>
          <p class="m-0 check-icon-indent"><app-icon-check size="25" icon_color="#0c6f5d" class="me-1"/>
            {{ $t('wz_case_form.promo.list_2_a') }} <strong>{{ $t('wz_case_form.promo.list_2_b') }}</strong> {{ $t('wz_case_form.promo.list_2_c') }}
          </p>
          <p class="m-0 check-icon-indent"><app-icon-check size="25" icon_color="#0c6f5d" class="me-1"/>
            <strong>{{ $t('wz_case_form.promo.list_3_a') }}</strong> {{ $t('wz_case_form.promo.list_3_b') }}
          </p>
          <p class="m-0 check-icon-indent"><app-icon-check size="25" icon_color="#0c6f5d" class="me-1"/>
            <strong>{{ $t('wz_case_form.promo.list_4_a') }}</strong> {{ $t('wz_case_form.promo.list_4_b') }}
          </p>
          <p class="m-0 check-icon-indent"><app-icon-check size="25" icon_color="#0c6f5d" class="me-1"/>
            <strong>{{ $t('wz_case_form.promo.list_5_a') }}</strong> {{ $t('wz_case_form.promo.list_5_b') }}
          </p>

          <p v-if="wz_case.is_data_safeguarded" class="m-0 check-icon-indent"><app-icon-check size="25" icon_color="#0c6f5d" class="me-1"/>
            {{$t('wz_case_form.promo.list_6') }}
          </p>

        </div>
      </div>
    </div>

  </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "PromoBox",
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters('WzCase', {wz_case: 'wz_case'}),
  }
}
</script>

<style scoped>
.check-icon-indent {
  padding-left: 30px;
  text-indent: -30px
}
</style>