<template>
  <div id="wz1-step-five" class="h-100 animate__animated animate__fadeInRight">

    <div id="select-payment-section" class="row align-items-center">
      <div class="col-xxl">
        <div class="card ccs-card-radius overflow-hidden border-4 w-100 shadow-sm h-100" style="min-height: 425px;">
          <div class="card-body">
            <h4 class="card-title">{{ $t('wz_case_form.wz1_step_five.title') }}</h4>
            <hr class="hr-ccs">
            <div class="row mb-3 mt-3">
              <div class="col-md-2"></div>
              <div class="col-md-8" style="font-size: 18px;">
                <div id="success_messages" class="mt-5">
                  <div id="answ_no" v-if="wz1_form.decision === 'no'">
                    <div id="answ_no_expertise_zero" v-if="wz_case.expertise_costs_gross_actual_amount <= 0">
                      <p class="h5 text-center">{{ $t('wz_case_form.wz1_step_five.messages.answ_no_1_a') }}</p>
                    </div>
                    <div id="answ_no_expertise" v-else>
                      <div id="answ_no_is_individual_customer" v-if="wz_case.is_individual_customer === true">
                        <div id="answ_no_cash_on_delivery_individual"
                             v-if="wz1_form.payment_type === 'cash_on_delivery'">
                          <p class="h5 text-center">{{ $t('wz_case_form.wz1_step_five.messages.answ_no_2_a') }}</p>
                        </div>
                        <div id="answ_no_payu_individual" v-else>
                          <p class="h5 text-center">{{ $t('wz_case_form.wz1_step_five.messages.answ_no_3_a') }}</p>
                        </div>
                      </div>
                      <div id="answ_no_is_not_individual_customer" v-else>
                        <div id="answ_no_cash_on_delivery_not_individual" v-if="wz_case.payment_method === 'POBRANIE'">
                          <p class="h5 text-center">{{ $t('wz_case_form.wz1_step_five.messages.answ_no_2_a') }}</p>
                        </div>
                        <div id="answ_no_banking_transfer_not_individual" v-else>
                          <p class="h5 text-center">{{ $t('wz_case_form.wz1_step_five.messages.answ_no_3_a') }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="answ_yes" v-if="wz1_form.decision === 'yes'">
                    <div id="answ_yes_is_individual_customer" v-if="wz_case.is_individual_customer === true">
                      <p class="h5 text-center">{{ $t('wz_case_form.wz1_step_five.messages.answ_yes_1_b') }}</p>
                    </div>
                    <div id="answ_yes_is_not_individual_customer" v-else>
                      <div id="answ_yes_cash_on_delivery_not_individual" v-if="wz_case.payment_method === 'POBRANIE'">
                        <p class="h5 text-center">{{ $t('wz_case_form.wz1_step_five.messages.answ_yes_1_b') }}</p>
                      </div>
                      <div id="answ_yes_banking_transfer_not_individual" v-else>
                        <p class="h5 text-center">{{ $t('wz_case_form.wz1_step_five.messages.answ_yes_2_b') }}</p>
                      </div>
                    </div>
                  </div>
                  <div id="answ_util" v-if="wz1_form.decision === 'util'">
                    <p class="h5 text-center">{{ $t('wz_case_form.wz1_step_five.messages.answ_util_1_c') }}</p>
                  </div>
                  <br/>
                  <p class="h5 text-center">{{ $t('payment.finished.message_2') }}</p>
                  <p class="h5 text-center"><app-icon-globe size="25" icon_color="#0c6f5d"/>&nbsp;&nbsp;
                    <a href="https://www.ccsonline.pl">www.ccsonline.pl</a>
                  </p>
                </div>
              </div>
              <div class="col-md-2"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Summary",
  computed: {
    ...mapGetters('WzCase', {wz_case: 'wz_case', wz_case_decision: 'wz_case_decision'}),
    ...mapGetters('Wz1From', {wz1_form: 'wz1_form', process_error: "error", process_loading: 'loading'})
  },
  methods: {
    ...mapActions('Wz1From', {setWz1Form:'setWz1Form'})
  },
  created() {
    if(this.wz1_form.decision === null) {
      this.setWz1Form({
        ...this.wz_case_decision
      })
    }
  }
}
</script>

<style scoped>

</style>