<template>

  <div>
  <div id="decision-form-70-77" class="h-100 animate__animated animate__fadeInLeft">
    <div class="container">
      <div class="row row-cols-3 justify-content-center">
        <div class="col-auto">
          <div id="odp_tak_card" class="card ccs-card-radius overflow-hidden border-4 shadow-sm mt-1 mb-4"
               :class="{ 'shadow-lg border-ccs': hover_answ_yes_card }"
               style="width: 26rem;"
               @mouseover="hover_answ_yes_card = true">
            <div class="card-body bg-light text-center border-4 border-white"
                 :class="{ 'bg-white border-ccs': hover_answ_yes_card }">
              <p class="fs-4"><b>
                {{ $t('wz_case_form.decision_form.answ_yes.title') }}<br/>
                {{ $t('wz_case_form.decision_form.answ_yes.subtitle') }}
              </b></p>
              <p class="fs-4 mb-3">
                {{ wz_case.costs.ALL.SUM.WITHOUT_PARTS_BACK.GROSS }}&nbsp;{{
                  $t('wz_case_form.decision_form.currency')
                }}
              </p>
            </div>
            <div class="footer bg-ccs-light p-3 mh-350"
                 :class="{ 'bg-ccs-darker': hover_answ_yes_card }">
              <div class="row justify-content-center">
                <div class="col text-center">
                  <button class="btn btn-outline-ccs btn-sem"
                          :class="{ 'btn-ccs': hover_answ_yes_card }"
                          @click="selectDecision('yes')">
                    <b>{{ $t('wz_case_form.decision_form.button_order_repair2') }}</b>
                  </button>
                </div>
              </div>
              <div class="row justify-content">
                <div class="col-auto text-center mt-4 mb-4">
                  {{ $t('wz_case_form.decision_form.answ_yes.description') }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-auto mt-4">
          <div id="odp_util_card" class="card ccs-card-radius overflow-hidden border-4 shadow-sm  mt-lg-5 mb-4 mh-350"
               :class="{ 'shadow-lg border-ccs': hover_answ_util_card }"
               style="width: 20rem;"
               @mouseover="hover_answ_util_card = true; hover_answ_yes_card = false"
               @mouseleave="hover_answ_util_card = false; hover_answ_yes_card = true">
            <div class="card-body bg-light text-center border-4 border-white"
                 :class="{ 'bg-white border-ccs': hover_answ_util_card }">
              <p class="fs-4 mt-2"><b>{{ $t('wz_case_form.decision_form.answ_util.title') }}</b></p><br/>
              <p class="fs-4 mb-3">0,00&nbsp;{{ $t('wz_case_form.decision_form.currency') }}</p>
            </div>
            <div class="footer bg-ccs-light p-3 mh-300"
                 :class="{ 'bg-ccs-darker': hover_answ_util_card }">
              <div class="row justify-content-center">
                <div class="col text-center">
                  <button class="btn btn-outline-ccs btn-sem"
                          :class="{ 'btn-ccs': hover_answ_util_card }"
                          @click="confirmDecision('util')">
                    <b>{{ $t('wz_case_form.decision_form.button_select') }}</b>
                  </button>
                </div>
              </div>
              <div class="row justify-content-center">
                <div class="col-auto text-center mt-4 mb-4">
                  {{ $t('wz_case_form.decision_form.answ_util.description_1') }}
                  <br><br>
                  {{ $t('wz_case_form.decision_form.answ_util.description_2') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    <app-confirmation-modal
        :message="confirm_message"
        ref="confirmationModal"
        @confirm="onConfirmDecision"
    />
  </div>

</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "StepOne70_77",
  data() {
    return {
      hover_answ_yes_card: true,
      hover_answ_util_card: false,
      confirm_message: '',
      decision: ''
    }
  },
  computed: {
    ...mapGetters('WzCase', {wz_case: 'wz_case'}),
  },
  methods: {
    confirmDecision: function($decision){
      if($decision === 'no') {
        this.confirm_message = this.$t('wz_case_form.decision_form.answ_no.confirmation');
      }
      if($decision === 'util') {
        this.confirm_message = this.$t('wz_case_form.decision_form.answ_util.confirmation');
      }
      this.decision = $decision;
      this.$refs.confirmationModal.show();
    },
    onConfirmDecision: function () {
      this.selectDecision(this.decision);
    },
    selectDecision: function (decision) {
      document.getElementById("decision-form-70-77").classList = "h-100 animate__animated animate__fadeOutLeft";
      setTimeout((function () {
        this.$emit('setDecision', decision);
      }).bind(this), 600);
    },
  }
}
</script>

<style scoped>

</style>