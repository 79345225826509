import { createStore } from 'vuex'

/** Import modules */
import modules from "./modules/modules";

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules
})
