import WzCaseDecisionService from "@/services/WzCaseDecisionService";

export const namespaced = true;

const getDefaultState = () => {
    return {
        wz1_form: {
            case_guid: null,
            ip: null,
            decision: null,
            order_number: null,
            with_parts_back: false,
            payment_type: null, //'cash_on_delivery'
            payment_operator : null, //'P24'
            immediate_consent: null,
            wz_costs_selected: null
        },

        payment_redirect_url: null,

        loading: false,
        error: false,
    }
}

export const state = getDefaultState();

export const mutations = {
    SET_WZ1_FORM(state, wz1_form) {
        state.wz1_form = wz1_form;
    },
    UPDATE_WZ1_FORM(state, payload) {
        state.wz1_form[payload.key] = payload.value;
    },
    SYNC_WZ1_FORM_FIELD_VALUES(state, payload) {
        if (state.wz1_form[payload.key].includes(payload.value)) {
            state.wz1_form[payload.key] = state.wz1_form[payload.key].filter(function (value) {
                if (value !== payload.value)
                    return value;
            });
        } else {
            state.wz1_form[payload.key].push(payload.value)
        }
    },
    SET_PAYMENT_REDIRECT_URL(state, payment_redirect_url) {
        state.payment_redirect_url = payment_redirect_url;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    RESET_STATE(state) {
        Object.assign(state, getDefaultState())
    }
};

export const actions = {
    setWz1Form({commit}, wz1_form) {
        commit("SET_LOADING", true);
        commit("SET_ERROR", false);
        commit("SET_WZ1_FORM", wz1_form);
        commit("SET_LOADING", false);
    },
    updateWz1Form({commit}, payload) {
        commit("SET_LOADING", true);
        commit("SET_ERROR", false);
        commit("UPDATE_WZ1_FORM", payload);
        commit("SET_LOADING", false);
    },
    syncValue({commit}, payload) {
        commit("SET_LOADING", true);
        commit("SET_ERROR", false);
        commit("SYNC_WZ1_FORM_FIELD_VALUES", payload);
        commit("SET_LOADING", false);
    },
    storeWzCaseDecision({commit, state}) {
        commit("SET_LOADING", true);
        commit("SET_ERROR", false);
        return WzCaseDecisionService.store({
            ...state.wz1_form
        }).then((response) => {
            if(state.wz1_form.payment_type === 'online_payment') {
                commit("SET_PAYMENT_REDIRECT_URL", response.data.payment_redirect_url);
                location.href = response.data.payment_redirect_url;
                return false;
            } else {
                return true;
            }
        }).catch(() => {
            commit("SET_ERROR", true);
            return false;
        }).finally(() => {
            // document.getElementById("step-5-loader").classList = "h-100 animate__animated animate__fadeOutLeft";
            setTimeout((function () {
                commit("SET_LOADING", false);
            }).bind(this), 600);
        });
    },
    destroyWzCaseDecision({commit}, case_guid) {
        commit("SET_LOADING", true);
        commit("SET_ERROR", false);
        return WzCaseDecisionService.destroy({
            case_guid: case_guid
        }).then(() => {
            commit("SET_LOADING", false);
            return true;
        }).catch(() => {
            commit("SET_LOADING", false);
            commit("SET_ERROR", true);
            return false;
        })
    },


    resetState({commit}) {
        commit('RESET_STATE');
    },
};

export const getters = {
    wz1_form: (state) => {
        return state.wz1_form;
    },
    loading: (state) => {
        return state.loading;
    },
    payment_redirect_url: (state) => {
        return state.payment_redirect_url;
    },
    error: (state) => {
        return state.error;
    },
};