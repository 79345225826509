<template>

  <div id="service-order-data" class="h-100">
    <div class="card ccs-card-radius w-100 shadow-sm h-100">
      <div class="card-body">
        <h4 class="card-title">{{ $t('wz_case_form.service_order_data.title') }}</h4>
        <hr class="hr-ccs">
        <h4 class="card-title text-center mt-5 mb-5 text-ccs"><b>
          {{ wz_case.maker }} <br>&nbsp;{{ (wz_case.device_business_name) ? wz_case.device_business_name : wz_case.invent_model_id }}
        </b></h4>
        <h6 class="card-title">
          {{ $t('wz_case_form.service_order_data.service_order_id') }}&nbsp;{{ wz_case.service_order_id }}
        </h6>
        <h6 class="card-title">{{ $t('wz_case_form.service_order_data.pos_number') }}&nbsp;{{ wz_case.pos_number }}</h6>
        <h6 class="card-title">{{ $t('wz_case_form.service_order_data.imei_sn') }}&nbsp;{{ wz_case.sn_imei_1 }}</h6>
      </div>
    </div>
  </div>

</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ServiceOrderData",
  computed: {
    ...mapGetters('WzCase', {wz_case: 'wz_case'}),
  }
}
</script>

<style scoped>

</style>