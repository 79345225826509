import {mapActions, mapGetters} from "vuex";

export const Wz1FormProcess = {
    data() {
        return {
            step: 1,
            step_prev: 1,
            timer: true,
        }
    },
    computed: {
        ...mapGetters('WzCase', {
            wz_case: 'wz_case',
            wz_case_decision: 'wz_case_decision',
            payment_transaction: 'payment_transaction',
            attachments: 'attachments',
            user_ip: 'user_ip'
        }),
        ...mapGetters('Wz1From', {
            wz1_form: 'wz1_form',
            payment_redirect_url: 'payment_redirect_url',
            process_loading: 'loading',
            process_error: 'error'
        }),
    },
    methods: {
        ...mapActions('Wz1From', {updateWz1Form: 'updateWz1Form', storeWzCaseDecision: 'storeWzCaseDecision'}),
        /**
         * Go back to previous step
         */
        backToPrevStep: function () {
            this.step = this.step_prev;
            if (this.step_prev !== 1) {
                this.step_prev = this.step_prev - 1;
            }
        },
        /**
         * Save selected decision
         *
         * @param decision
         */
        selectDecision: function (decision) {
            /** Save selected decision */
            this.updateWz1Form({
                key: 'decision',
                value: decision
            });
            /** Submit step 1 */
            this.submitStep(1);
        },
        /**
         * Submit current step
         *
         * @param step
         */
        submitStep: function (step) {
            this.step_prev = step;
            let next_step = null;
            switch (step) {
                case 1:
                    switch (this.wz1_form.decision) {
                        case 'no':
                            this.showQuestionnaireForm();
                            if (this.wz_case.expertise_costs_gross_actual_amount <= 0) {
                                next_step = 5;
                            } else {
                                if (this.wz_case.require_order_number === true) {
                                    next_step = 2;
                                } else {
                                    if (this.wz_case.is_individual_customer === true) {
                                        next_step = 4;
                                    } else {
                                        next_step = 5;
                                    }
                                }
                            }
                            break;
                        case 'yes':
                            if (this.wz_case.require_order_number === true) {
                                next_step = 2;
                            } else {
                                if (this.wz_case.is_individual_customer === true) {
                                    if (this.wz_case.fax_id === 70 || this.wz_case.fax_id === 77) {
                                        next_step = 4;
                                    } else {
                                        next_step = 3;
                                    }
                                } else {
                                    next_step = 5;
                                }
                            }
                            break;
                        case 'util':
                            if (this.wz_case.is_individual_customer === true) {
                                next_step = 3;
                            } else {
                                next_step = 5;
                            }
                            break;
                    }
                    break;
                case 2:
                    switch (this.wz1_form.decision) {
                        case 'no':
                            if (this.wz_case.is_individual_customer === true) {
                                next_step = 4;
                            } else {
                                next_step = 5;
                            }
                            break;
                        case 'yes':
                            if (this.wz_case.is_individual_customer === true) {
                                if (this.wz_case.fax_id === 70 || this.wz_case.fax_id === 77) {
                                    next_step = 4;
                                } else {
                                    next_step = 3;
                                }
                            } else {
                                next_step = 5;
                            }
                            break;
                    }
                    break;
                case 3:
                    switch (this.wz1_form.decision) {
                        case 'util':
                            next_step = 5;
                            break;
                        case 'yes':
                            next_step = 4;
                            break;
                    }
                    break;
                case 4:
                    next_step = 5;
                    break;
            }
            if (next_step === 5) {
                this.timer = false;
                this.submitWzCaseDecision();
            } else {
                this.step = next_step;
            }
        },
        /**
         * Submit (send to backend) selected decision
         */
        submitWzCaseDecision: function () {
            let storeStatus =
                this.storeWzCaseDecision();
            const checkStatus = async () => {
                const status = await storeStatus;
                if (status) {
                    this.step = 5;
                }
            };
            checkStatus();
        },
    }
}