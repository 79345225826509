<template>
  <div id="wz1-step-four" class="h-100 animate__animated animate__fadeInRight">

    <div id="select-payment-section" class="row align-items-center">
      <div class="col-xxl">
        <div class="card ccs-card-radius overflow-hidden border-4 w-100 shadow-sm h-100" style="min-height: 425px;">
          <div class="card-body">
            <h4 class="card-title">{{ $t('wz_case_form.wz1_step_four.title') }}</h4>
            <hr class="hr-ccs">
            <div class="row mb-3 mt-5">
              <div class="col-md-2"></div>
              <div class="col-md-8">
                <div v-if="v_error" class="alert alert-danger" role="alert">
                  {{ v_validation_error }}
                </div>
                <div v-else class="alert">
                  &nbsp;
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" id="payment_type_1"
                         :value="'online_payment'"
                         v-model="payment_type" @change="changePaymentType">
                  <label class="form-check-label text-justify " for="payment_type_1">
                    {{ $t('wz_case_form.wz1_step_four.payment_online') }}
                  </label>
                </div>
                <div class="form-check" :hidden="wz_case.amount_paid_online > 0">
                  <input class="form-check-input" type="radio" id="payment_type_2"
                         :value="'cash_on_delivery'"
                         v-model="payment_type" @change="changePaymentType">
                  <label class="form-check-label text-justify " for="payment_type_2">
                    {{ $t('wz_case_form.wz1_step_four.cash_on_delivery') }}
                  </label>
                </div>
              </div>
              <div class="col-md-2"></div>
            </div>
            <div class="row mb-5 mt-3">
              <div class="col-md-2"></div>
              <div class="col-md-8">
                <div v-if="payment_type == 'cash_on_delivery'">
                  {{ $t('wz_case_form.wz1_step_four.cash_on_delivery_description') }}
                </div>
                <div v-else>&nbsp;</div>
              </div>
              <div class="col-md-2"></div>
            </div>
          </div>

          <div class="card-footer bg-ccs-light">
            <div class="d-flex justify-content-around flex-wrap-reverse">
              <div class="p-2">
                <button class="btn btn-outline-ccs min-w-200 mt-2 mb-2" @click="backToPrevStep">{{ $t('wz_case_form.btn_back') }}</button>
              </div>
              <div class="p-2">
                <button class="btn btn-ccs min-w-200 mt-2 mb-2" @click="submitStepFour">{{ $t('wz_case_form.btn_accept') }}</button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>


  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {Wz1FormValidator} from "@/mixins/Wz1FormValidator";

export default {
  name: "StepThree",
  mixins: [Wz1FormValidator],
  data() {
    return {
      pt: null,
    }
  },
  computed: {
    ...mapGetters('WzCase', {wz_case: 'wz_case'}),
    ...mapGetters('Wz1From', {wz1_form: 'wz1_form'}),
    payment_type: {
      get() {
        if (this.wz_case.amount_paid_online > 0) {
          this.updateWz1Form({
            key: 'payment_type',
            value: 'online_payment'
          });
          this.updateWz1Form({
            key: 'payment_operator',
            value: 'P24'
          });
          return 'online_payment';
        }
        return this.pt;
      },
      set(newValue) {
        this.pt = newValue;
      }
    }
  },
  methods: {
    ...mapActions('Wz1From', {updateWz1Form: 'updateWz1Form'}),
    changePaymentType: function () {
      this.v_resetError();
      this.updateWz1Form({
        key: 'payment_type',
        value: this.payment_type
      });
      if (this.payment_type === 'online_payment') {
        this.updateWz1Form({
          key: 'payment_operator',
          value: 'P24'
        });
      }
    },
    backToPrevStep: function () {
      document.getElementById("wz1-step-four").classList = "h-100 animate__animated animate__fadeOutRight";
      setTimeout((function () {
        this.$emit('backToPrevStep');
      }).bind(this), 600);
    },
    submitStepFour: function () {
      if (this.v_validatePaymentType(this.payment_type)) {
        document.getElementById("wz1-step-four").classList = "h-100 animate__animated animate__fadeOutLeft";
        setTimeout((function () {
          this.$emit('submitStepFour');
        }).bind(this), 600);
      }
    }
  },
  mounted() {
    this.payment_type = this.wz1_form.payment_type;
  }
}
</script>

<style scoped>

.min-w-200 {
  min-width: 200px;
}

</style>