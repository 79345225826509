<template>

  <div id="wz-details" class="h-100">

    <div class="card ccs-card-radius w-100 shadow-sm h-100 mb-2">
      <div class="card-body h-100">
        <p class="fs-5-ccs-special text-left text-sm-justify mt-3 mb-3 font-weight-bold">{{ wz_case.fax_content }}</p>
        <p v-if="wz_case_optional && this.wz_case.costs.OPTIONS.REQ_I.PRESENT && this.wz_case.costs.OPTIONS.REQ_II.PRESENT"
           class="fs-5-ccs-special text-left text-sm-justify mt-3 mb-0 font-weight-bold">
          {{ $t('wz_case_form.wz_details.options_title') }}<sup>*</sup>:
        </p>

<!--        old version-->
        <div v-if="!wz_case_optional">
          <div class="row justify-content-center">
            <div class="col-auto w-75">
              <table class="table table-sm  table-hover border-ccs">
                <thead class="text-center">
                <tr class="bg-ccs text-white">
                  <td colspan="2">{{ $t('wz_case_form.wz_details.costs_table.service_charge') }}</td>
                </tr>
                </thead>
                <tbody>
                <tr id="sumCostsWithoutPartsBack" v-if="wz1_form.with_parts_back === false">
                  <td align="center">{{
                      formatPrice(wz_case.costs.ALL.SUM.WITHOUT_PARTS_BACK.NET)
                    }}&nbsp;{{ $t('wz_case_form.wz_details.costs_table.net_price') }}
                  </td>
                  <td align="center">{{
                      formatPrice(wz_case.costs.ALL.SUM.WITHOUT_PARTS_BACK.GROSS)
                    }}&nbsp;{{ $t('wz_case_form.wz_details.costs_table.gross_price') }}
                  </td>
                </tr>
                <tr id="sumCostsWithPartsBack" v-if="wz1_form.with_parts_back === true">
                  <td align="center">{{
                      formatPrice(wz_case.costs.ALL.SUM.WITH_PARTS_BACK.NET)
                    }}&nbsp;{{ $t('wz_case_form.wz_details.costs_table.net_price') }}
                  </td>
                  <td align="center">{{
                      formatPrice(wz_case.costs.ALL.SUM.WITH_PARTS_BACK.GROSS)
                    }}&nbsp;{{ $t('wz_case_form.wz_details.costs_table.gross_price') }}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-auto w-75">
              <table class="table table-sm  table-hover border-ccs">
                <thead class="text-center">
                <tr class="bg-ccs text-white">
                  <td>{{ $t('wz_case_form.wz_details.costs_table.detailed_information') }}</td>
                </tr>
                </thead>
                <tbody class="text-center">
                <tr v-for="part in wz_case.costs.PARTS.ITEMS"
                    :key="part.item_id">
                  <td>{{ part.description }}</td>
                </tr>
                <tr id="additionalCostsPartsBack" v-if="wz1_form.with_parts_back === true">
                  <td>{{ $t('wz_case_form.wz_details.costs_table.get_back_parts') }}</td>
                </tr>
                <tr v-if="wz_case.costs.TRANSPORT.SUM.NET !== 0">
                  <td>{{ $t('wz_case_form.wz_details.costs_table.transport') }}</td>
                </tr>
                <tr v-if="wz_case.costs.SERVICES.SUM.NET !== 0">
                  <td>{{ $t('wz_case_form.wz_details.costs_table.service') }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

<!--        new version-->
        <div class="row justify-content-center pb-3" v-if="wz_case_optional">

          <div class="col col-sm-6 mt-4" v-if="this.wz_case.costs.OPTIONS.REQ_I.PRESENT">
            <div id="req_I_card" class="card ccs-card ccs-card-radius overflow-hidden h-100 shadow-sm mb-4 border-4 shadow-lg"
                 :class="{ 'border-ccs': this.wz_case.costs.SELECTED.ESTIMATE_VERSION === 'REQ_I'}"
                 @mouseover="hover_req_I_card = true;"
                 @mouseleave="hover_req_I_card = false;">
              <div class="card-body bg-light text-center"
                   :class="{ 'bg-white border-ccs': hover_req_I_card  || this.wz_case.costs.SELECTED.ESTIMATE_VERSION === 'REQ_I'}">
                <p class="fs-6 mt-2 mb-3"><b>{{ $t('wz_case_form.wz_details.costs_table.detailed_information') }}</b></p>

                <table class="table table-sm border-ccs">
                  <tbody class="text-center">
                  <tr v-for="part in wz_case.costs.OPTIONS.REQ_I.PARTS.ITEMS"
                      :key="part.item_id">
                    <td>{{ partName(part.description) }}</td>
                  </tr>
                  <tr id="additionalCostsPartsBack" v-if="wz1_form.with_parts_back === true">
                    <td>{{ $t('wz_case_form.wz_details.costs_table.get_back_parts') }}</td>
                  </tr>
                  <tr v-if="wz_case.costs.OPTIONS.REQ_I.TRANSPORT?.SUM.NET !== 0">
                    <td>{{ $t('wz_case_form.wz_details.costs_table.transport') }}</td>
                  </tr>
                  <tr v-if="wz_case.costs.OPTIONS.REQ_I.SERVICES?.SUM.NET !== 0">
                    <td>{{ $t('wz_case_form.wz_details.costs_table.service') }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="footer bg-ccs-light p-3"
                   :class="{ 'bg-ccs-darker': hover_req_I_card || this.wz_case.costs.SELECTED.ESTIMATE_VERSION === 'REQ_I' }">
                <div class="row justify-content-center">
                  <div class="col text-center">
                    <button class="btn btn-outline-ccs btn-sem"
                            :class="{ 'btn-ccs': hover_req_I_card || (this.wz_case.costs.SELECTED.ESTIMATE_VERSION === 'REQ_I') }"
                            @click="this.wz_case.costs.SELECTED.ESTIMATE_VERSION = 'REQ_I';">
                      <b>{{ $t('wz_case_form.decision_form.button_order_repair') }}</b>
                    </button>
                  </div>
                </div>
                <div class="row justify-content-center">
                  <div class="col-auto text-center">
                    <table class="table table-sm mb-3">
                      <tbody>
                      <tr id="sumCostsWithoutPartsBack" v-if="wz1_form.with_parts_back === false">
                        <td class="border-0 font-weight-bold" align="center">{{
                            formatPrice(wz_case.costs.OPTIONS.REQ_I.ALL.SUM.WITHOUT_PARTS_BACK.NET)
                          }}&nbsp;{{ $t('wz_case_form.wz_details.costs_table.net_price') }}
                        </td>
                        <td class="border-0 font-weight-bold" align="center">{{
                            formatPrice(wz_case.costs.OPTIONS.REQ_I.ALL.SUM.WITHOUT_PARTS_BACK.GROSS)
                          }}&nbsp;{{ $t('wz_case_form.wz_details.costs_table.gross_price') }}
                        </td>
                      </tr>
                      <tr id="sumCostsWithPartsBack" v-if="wz1_form.with_parts_back === true">
                        <td class="border-0 font-weight-bold" align="center">{{
                            formatPrice(wz_case.costs.OPTIONS.REQ_I.ALL.SUM.WITH_PARTS_BACK.NET)
                          }}&nbsp;{{ $t('wz_case_form.wz_details.costs_table.net_price') }}
                        </td>
                        <td class="border-0 font-weight-bold" align="center">{{
                            formatPrice(wz_case.costs.OPTIONS.REQ_I.ALL.SUM.WITH_PARTS_BACK.GROSS)
                          }}&nbsp;{{ $t('wz_case_form.wz_details.costs_table.gross_price') }}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col col-sm-6 mt-4" v-if="this.wz_case.costs.OPTIONS.REQ_II.PRESENT">
            <div id="req_II_card" class="card ccs-card ccs-card-radius overflow-hidden h-100 shadow-sm mb-4 border-4 shadow-lg"
                 :class="{ 'border-ccs': this.wz_case.costs.SELECTED.ESTIMATE_VERSION === 'REQ_II'}"
                 @mouseover="hover_req_II_card = true;"
                 @mouseleave="hover_req_II_card = false;">
              <div class="card-body bg-light text-center"
                   :class="{ 'bg-white border-ccs': hover_req_II_card  || this.wz_case.costs.SELECTED.ESTIMATE_VERSION === 'REQ_II'}">
                <p class="fs-6 mt-2 mb-3"><b>{{ $t('wz_case_form.wz_details.costs_table.detailed_information') }}</b></p>

                <table class="table table-sm border-ccs">
                  <tbody class="text-center">
                  <tr v-for="part in wz_case.costs.OPTIONS.REQ_II.PARTS.ITEMS"
                      :key="part.item_id">
                    <td>{{ partName(part.description) }}</td>
                  </tr>
                  <tr id="additionalCostsPartsBack" v-if="wz1_form.with_parts_back === true">
                    <td>{{ $t('wz_case_form.wz_details.costs_table.get_back_parts') }}</td>
                  </tr>
                  <tr v-if="wz_case.costs.OPTIONS.REQ_II.TRANSPORT?.SUM.NET !== 0">
                    <td>{{ $t('wz_case_form.wz_details.costs_table.transport') }}</td>
                  </tr>
                  <tr v-if="wz_case.costs.OPTIONS.REQ_II.SERVICES?.SUM.NET !== 0">
                    <td>{{ $t('wz_case_form.wz_details.costs_table.service') }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="footer bg-ccs-light p-3"
                   :class="{ 'bg-ccs-darker': hover_req_II_card || this.wz_case.costs.SELECTED.ESTIMATE_VERSION === 'REQ_II' }">
                <div class="row justify-content-center">
                  <div class="col text-center">
                    <button class="btn btn-outline-ccs btn-sem"
                            :class="{ 'btn-ccs': hover_req_II_card || (this.wz_case.costs.SELECTED.ESTIMATE_VERSION === 'REQ_II') }"
                            @click="this.wz_case.costs.SELECTED.ESTIMATE_VERSION = 'REQ_II';">
                      <b>{{ $t('wz_case_form.decision_form.button_order_repair') }}</b>
                    </button>
                  </div>
                </div>
                <div class="row justify-content-center">
                  <div class="col-auto text-center">
                    <table class="table table-sm mb-3">
                      <tbody>
                      <tr id="sumCostsWithoutPartsBack" v-if="wz1_form.with_parts_back === false">
                        <td class="border-0 font-weight-bold" align="center">{{
                            formatPrice(wz_case.costs.OPTIONS.REQ_II.ALL.SUM.WITHOUT_PARTS_BACK.NET)
                          }}&nbsp;{{ $t('wz_case_form.wz_details.costs_table.net_price') }}
                        </td>
                        <td class="border-0 font-weight-bold" align="center">{{
                            formatPrice(wz_case.costs.OPTIONS.REQ_II.ALL.SUM.WITHOUT_PARTS_BACK.GROSS)
                          }}&nbsp;{{ $t('wz_case_form.wz_details.costs_table.gross_price') }}
                        </td>
                      </tr>
                      <tr id="sumCostsWithPartsBack" v-if="wz1_form.with_parts_back === true">
                        <td class="border-0 font-weight-bold" align="center">{{
                            formatPrice(wz_case.costs.OPTIONS.REQ_II.ALL.SUM.WITH_PARTS_BACK.NET)
                          }}&nbsp;{{ $t('wz_case_form.wz_details.costs_table.net_price') }}
                        </td>
                        <td class="border-0 font-weight-bold" align="center">{{
                            formatPrice(wz_case.costs.OPTIONS.REQ_II.ALL.SUM.WITH_PARTS_BACK.GROSS)
                          }}&nbsp;{{ $t('wz_case_form.wz_details.costs_table.gross_price') }}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div id="options_annotation" class="fs-6 mt-3" v-if="wz_case_optional && this.wz_case.costs.OPTIONS.REQ_I.PRESENT && this.wz_case.costs.OPTIONS.REQ_II.PRESENT">
          <p class="mb-0"><sup>*</sup>{{ $t('wz_case_form.wz_details.options_annotation_1') }}</p>
          <ol class="ps-4 mb-0">
            <li>{{ $t('wz_case_form.wz_details.options_annotation_2') }}</li>
            <li>{{ $t('wz_case_form.wz_details.options_annotation_3') }}</li>
          </ol>
        </div>

      </div>
    </div>

    <div class="card ccs-card-radius w-100 shadow-sm h-100 mb-2 pb-3" v-if="hasRecommendations()">
      <div class="card-body h-100">
      <div id="additional-elements">
      <p class="fs-5-ccs-special text-justify px-2 mt-1 mb-0 font-weight-bold">{{ $t('wz_case_form.decision_form.additional_elements') }}</p>
      <div class="row justify-content-center">
        <div class="col col-sm-6 mt-4" v-if="wz_case.costs.OPTIONS.RECOMMEND_I.PRESENT">
          <div id="recommend_I_card" class="card ccs-card ccs-card-radius overflow-hidden h-100 shadow-sm mb-4 border-4 shadow-lg"
               :class="{ 'border-ccs': wz_case.costs.SELECTED.RECOMMENDATIONS.RECOMMEND_I }"
               @mouseover="hover_recommend_I_card = true;"
               @mouseleave="hover_recommend_I_card = false;">
            <div class="card-body bg-light text-center"
                 :class="{ 'bg-white border-ccs': hover_recommend_I_card  || wz_case.costs.SELECTED.RECOMMENDATIONS.RECOMMEND_I}">

              <p class="fs-6 mt-2 mb-3" v-for="part in wz_case.costs.OPTIONS.RECOMMEND_I.PARTS.ITEMS" :key="part.item_id" >
                <b>{{ partName(part.description) }}</b>
              </p>

              <p class="fs-6 mt-2 mb-0"  v-for="fault in recommend_faults.RECOMMEND_I" :key="fault" >
                {{fault}}
              </p>

            </div>
            <div class="footer bg-ccs-light p-3"
                 :class="{ 'bg-ccs-darker': hover_recommend_I_card || wz_case.costs.SELECTED.RECOMMENDATIONS.RECOMMEND_I }">
              <div class="row justify-content-center">
                <div class="col text-center">
                  <button class="btn btn-outline-ccs btn-sem"
                          :class="{ 'btn-ccs': (hover_recommend_I_card || wz_case.costs.SELECTED.RECOMMENDATIONS.RECOMMEND_I) }"
                          @click="onRecommendSelect('RECOMMEND_I')">
                    <b>{{ $t('wz_case_form.decision_form.button_add_to_repair') }}</b>
                  </button>
                </div>
              </div>
              <div class="row justify-content-center">
                <div class="col-auto text-center">
                  <table class="table table-sm mb-3">
                    <tbody>
                    <tr>
                      <td class="border-0 font-weight-bold" align="center">{{
                          formatPrice(wz_case.costs.OPTIONS.RECOMMEND_I.PARTS.SUM.NET)
                        }}&nbsp;{{ $t('wz_case_form.wz_details.costs_table.net_price') }}
                      </td>
                      <td class="border-0 font-weight-bold" align="center">{{
                          formatPrice(wz_case.costs.OPTIONS.RECOMMEND_I.PARTS.SUM.GROSS)
                        }}&nbsp;{{ $t('wz_case_form.wz_details.costs_table.gross_price') }}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col col-sm-6 mt-4" v-if="wz_case.costs.OPTIONS.RECOMMEND_II.PRESENT">
          <div id="recommend_II_card" class="card ccs-card ccs-card-radius overflow-hidden h-100 shadow-sm mb-4 border-4 shadow-lg"
               :class="{ 'border-ccs': wz_case.costs.SELECTED.RECOMMENDATIONS.RECOMMEND_II }"
               @mouseover="hover_recommend_II_card = true;"
               @mouseleave="hover_recommend_II_card = false;">
            <div class="card-body bg-light text-center"
                 :class="{ 'bg-white border-ccs': hover_recommend_II_card  || wz_case.costs.SELECTED.RECOMMENDATIONS.RECOMMEND_II}">

              <p class="fs-6 mt-2 mb-3" v-for="part in wz_case.costs.OPTIONS.RECOMMEND_II.PARTS.ITEMS" :key="part.item_id" >
                <b>{{ partName(part.description) }}</b>
              </p>

              <p class="fs-6 mt-2 mb-0"  v-for="fault in recommend_faults.RECOMMEND_II" :key="fault" >
                {{fault}}
              </p>

            </div>
            <div class="footer bg-ccs-light p-3"
                 :class="{ 'bg-ccs-darker': hover_recommend_II_card || wz_case.costs.SELECTED.RECOMMENDATIONS.RECOMMEND_II }">
              <div class="row justify-content-center">
                <div class="col text-center">
                  <button class="btn btn-outline-ccs btn-sem"
                          :class="{ 'btn-ccs': (hover_recommend_II_card || wz_case.costs.SELECTED.RECOMMENDATIONS.RECOMMEND_II) }"
                          @click="onRecommendSelect('RECOMMEND_II')">
                    <b>{{ $t('wz_case_form.decision_form.button_add_to_repair') }}</b>
                  </button>
                </div>
              </div>
              <div class="row justify-content-center">
                <div class="col-auto text-center">
                  <table class="table table-sm mb-3">
                    <tbody>
                    <tr>
                      <td class="border-0 font-weight-bold" align="center">{{
                          formatPrice(wz_case.costs.OPTIONS.RECOMMEND_II.PARTS.SUM.NET)
                        }}&nbsp;{{ $t('wz_case_form.wz_details.costs_table.net_price') }}
                      </td>
                      <td class="border-0 font-weight-bold" align="center">{{
                          formatPrice(wz_case.costs.OPTIONS.RECOMMEND_II.PARTS.SUM.GROSS)
                        }}&nbsp;{{ $t('wz_case_form.wz_details.costs_table.gross_price') }}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col col-sm-6 mt-4" v-if="wz_case.costs.OPTIONS.RECOMMEND_III.PRESENT">
          <div id="recommend_III_card" class="card ccs-card ccs-card-radius overflow-hidden h-100 shadow-sm mb-4 border-4 shadow-lg"
               :class="{ 'border-ccs': wz_case.costs.SELECTED.RECOMMENDATIONS.RECOMMEND_III }"
               @mouseover="hover_recommend_III_card = true;"
               @mouseleave="hover_recommend_III_card = false;">
            <div class="card-body bg-light text-center"
                 :class="{ 'bg-white border-ccs': hover_recommend_III_card  || wz_case.costs.SELECTED.RECOMMENDATIONS.RECOMMEND_III}">

              <p class="fs-6 mt-2 mb-3" v-for="part in wz_case.costs.OPTIONS.RECOMMEND_III.PARTS.ITEMS" :key="part.item_id" >
                <b>{{ partName(part.description) }}</b>
              </p>

              <p class="fs-6 mt-2 mb-0"  v-for="fault in recommend_faults.RECOMMEND_III" :key="fault" >
                {{fault}}
              </p>

            </div>
            <div class="footer bg-ccs-light p-3"
                 :class="{ 'bg-ccs-darker': hover_recommend_III_card || wz_case.costs.SELECTED.RECOMMENDATIONS.RECOMMEND_III }">
              <div class="row justify-content-center">
                <div class="col text-center">
                  <button class="btn btn-outline-ccs btn-sem"
                          :class="{ 'btn-ccs': (hover_recommend_III_card || wz_case.costs.SELECTED.RECOMMENDATIONS.RECOMMEND_III) }"
                          @click="onRecommendSelect('RECOMMEND_III')">
                    <b>{{ $t('wz_case_form.decision_form.button_add_to_repair') }}</b>
                  </button>
                </div>
              </div>
              <div class="row justify-content-center">
                <div class="col-auto text-center">
                  <table class="table table-sm mb-3">
                    <tbody>
                    <tr>
                      <td class="border-0 font-weight-bold" align="center">{{
                          formatPrice(wz_case.costs.OPTIONS.RECOMMEND_III.PARTS.SUM.NET)
                        }}&nbsp;{{ $t('wz_case_form.wz_details.costs_table.net_price') }}
                      </td>
                      <td class="border-0 font-weight-bold" align="center">{{
                          formatPrice(wz_case.costs.OPTIONS.RECOMMEND_III.PARTS.SUM.GROSS)
                        }}&nbsp;{{ $t('wz_case_form.wz_details.costs_table.gross_price') }}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      </div>
    </div>

    <div class="card ccs-card-radius overflow-hidden w-100 shadow-sm h-100 mb-2 pb-3" v-if="wz_case.costs.OPTIONS.ADDITIONAL.length">
      <div class="card-body h-100">
                  <div id="accessories">
                    <p class="fs-5-ccs-special text-justify px-2 mt-1 mb-0 w-100 font-weight-bold">{{ $t('wz_case_form.decision_form.additional_accessories') }}</p>
                    <div class="row justify-content-center">

                      <div class="col col-sm-6 mt-4"  v-for="(part, index) in wz_case.costs.OPTIONS.ADDITIONAL" :key="part.rec_id">
                        <div :id="`additional_${index+1}_card`" class="card ccs-card ccs-card-radius overflow-hidden h-100 shadow-sm mb-4 border-4 shadow-lg"
                             :class="{ 'border-ccs': wz_case.costs.SELECTED.ADDITIONAL.includes(part.rec_id) }"
                             @mouseover="hover_accessories_card[index] = true;"
                             @mouseleave="hover_accessories_card[index] = false;">
                          <div class="card-body bg-light text-center"
                               :class="{ 'bg-white border-ccs': hover_accessories_card[index]  || wz_case.costs.SELECTED.ADDITIONAL.includes(part.rec_id)}">

                            <p class="fs-6 mt-2 mb-0">
                              <b>{{ partName(part.description) }}</b>
                            </p>


                          </div>
                          <div class="footer bg-ccs-light p-3"
                               :class="{ 'bg-ccs-darker': hover_accessories_card[index] || wz_case.costs.SELECTED.ADDITIONAL.includes(part.rec_id) }">
                            <div class="row justify-content-center">
                              <div class="col text-center">
                                <button class="btn btn-outline-ccs btn-sem"
                                        :class="{ 'btn-ccs': (hover_accessories_card[index] || wz_case.costs.SELECTED.ADDITIONAL.includes(part.rec_id)) }"
                                        @click="onAdditionalSelect(part.rec_id)">
                                  <b>{{ $t('wz_case_form.decision_form.button_add_to_repair') }}</b>
                                </button>
                              </div>
                            </div>
                            <div class="row justify-content-center">
                              <div class="col-auto text-center">
                                <table class="table table-sm mb-3">
                                  <tbody>
                                  <tr>
                                    <td class="border-0 font-weight-bold" align="center">{{
                                        formatPrice(part.net_price)
                                      }}&nbsp;{{ $t('wz_case_form.wz_details.costs_table.net_price') }}
                                    </td>
                                    <td class="border-0 font-weight-bold" align="center">{{
                                        formatPrice(part.gross_price)
                                      }}&nbsp;{{ $t('wz_case_form.wz_details.costs_table.gross_price') }}
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
    </div>

    <div class="card ccs-card-radius overflow-hidden w-100 shadow-sm h-100 mb-2 pb-3" v-if="wz_case.costs.OPTIONS.FOIL_PROTECT.length">
      <div class="card-body h-100">
                <div id="protection" class="mb-0">
                  <p class="fs-5-ccs-special text-justify px-2 mt-1 mb-0 w-100 font-weight-bold">{{ $t('wz_case_form.decision_form.additional_protection') }}</p>
                  <div class="px-2">
                    <p class="m-0 check-icon-indent"><app-icon-check size="25" icon_color="#0c6f5d" class="me-1"/>{{ $t('wz_case_form.decision_form.additional_protection_list_1') }}</p>
                    <p class="m-0 check-icon-indent"><app-icon-check size="25" icon_color="#0c6f5d" class="me-1"/>{{ $t('wz_case_form.decision_form.additional_protection_list_2') }}</p>
                    <p class="m-0 check-icon-indent"><app-icon-check size="25" icon_color="#0c6f5d" class="me-1"/>{{ $t('wz_case_form.decision_form.additional_protection_list_3') }}</p>
                    <p class="m-0 check-icon-indent"><app-icon-check size="25" icon_color="#0c6f5d" class="me-1"/>{{ $t('wz_case_form.decision_form.additional_protection_list_4') }}</p>
                    <p class="m-0 check-icon-indent"><app-icon-check size="25" icon_color="#0c6f5d" class="me-1"/>{{ $t('wz_case_form.decision_form.additional_protection_list_5') }}</p>

                  </div>


                  <div class="row justify-content-center">
                    <div class="col col-sm-6 col-md-4 mt-4"  v-for="(part, index) in wz_case.costs.OPTIONS.FOIL_PROTECT" :key="part.rec_id">
                      <div id="`foil_${index+1}_card`" class="card ccs-card ccs-card-radius overflow-hidden h-100 shadow-sm mb-4 border-4 shadow-lg"
                           :class="{ 'border-ccs': (wz_case.costs.SELECTED.FOIL_PROTECT == part.rec_id) }"
                           @mouseover="hover_foil_card[index] = true;"
                           @mouseleave="hover_foil_card[index] = false;">
                        <div class="card-body bg-light"
                             :class="{ 'bg-white border-ccs': hover_foil_card[index]  || (wz_case.costs.SELECTED.FOIL_PROTECT == part.rec_id)}">

                          <p class="fs-6 mt-2 mb-0 text-center">
                            <b>{{ $t(`wz_case_form.wz_details.foil_protection['${part.estimate_type_item}'].title`)}}</b>
                          </p>
                          <p class="m-0 check-icon-indent" v-if="$te(`wz_case_form.wz_details.foil_protection['${part.estimate_type_item}'].list_1_a`)">
                            <app-icon-check size="25" icon_color="#0c6f5d" class="me-1"/>
                            <strong>{{  $t(`wz_case_form.wz_details.foil_protection['${part.estimate_type_item}'].list_1_a`) }}</strong> {{  $t(`wz_case_form.wz_details.foil_protection['${part.estimate_type_item}'].list_1_b`) }}
                          </p>
                          <p class="m-0 check-icon-indent" v-if="$te(`wz_case_form.wz_details.foil_protection['${part.estimate_type_item}'].list_2_a`)">
                            <app-icon-check size="25" icon_color="#0c6f5d" class="me-1"/>
                            <strong>{{  $t(`wz_case_form.wz_details.foil_protection['${part.estimate_type_item}'].list_2_a`) }}</strong> {{  $t(`wz_case_form.wz_details.foil_protection['${part.estimate_type_item}'].list_2_b`) }}
                          </p>
                          <p class="m-0 check-icon-indent" v-if="$te(`wz_case_form.wz_details.foil_protection['${part.estimate_type_item}'].list_3_a`)">
                            <app-icon-check size="25" icon_color="#0c6f5d" class="me-1"/>
                            <strong>{{  $t(`wz_case_form.wz_details.foil_protection['${part.estimate_type_item}'].list_3_a`) }}</strong> {{  $t(`wz_case_form.wz_details.foil_protection['${part.estimate_type_item}'].list_3_b`) }}
                          </p>


                        </div>
                        <div class="footer bg-ccs-light p-3"
                             :class="{ 'bg-ccs-darker': hover_foil_card[index] || (wz_case.costs.SELECTED.FOIL_PROTECT == part.rec_id) }">
                          <div class="row justify-content-center">
                            <div class="col text-center">
                              <button class="btn btn-outline-ccs btn-sem"
                                      :class="{ 'btn-ccs': (hover_foil_card[index] || (wz_case.costs.SELECTED.FOIL_PROTECT == part.rec_id) ) }"
                                      @click="onFoilSelect(part.rec_id)">
                                <b>{{ $t('wz_case_form.decision_form.button_add_to_repair') }}</b>
                              </button>
                            </div>
                          </div>
                          <div class="row justify-content-center">
                            <div class="col-auto text-center">
                              <table class="table table-sm mb-3">
                                <tbody>
                                <tr>
                                  <td class="border-0 font-weight-bold" align="center">{{
                                      formatPrice(part.net_price)
                                    }}&nbsp;{{ $t('wz_case_form.wz_details.costs_table.net_price') }}
                                  </td>
                                  <td class="border-0 font-weight-bold" align="center">{{
                                      formatPrice(part.gross_price)
                                    }}&nbsp;{{ $t('wz_case_form.wz_details.costs_table.gross_price') }}
                                  </td>
                                </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
    </div>

    <div id="priceInfo" v-show="showPriceInfo()" style="width: 175px; height: 80px; position: fixed; bottom: 49px; right: 30px; z-index: 99;">
      <div class="card bg-ccs p-1 text-white" v-if="wz1_form.with_parts_back !== true">
        <div class="row m-1 border-bottom">
          <div class="col p-0"><small>{{ $t('wz_case_form.wz_details.price_info.netto') }}: </small></div>
          <div class="col p-0 text-end">{{ wz_case.costs.ALL.SUM.WITHOUT_PARTS_BACK.NET }}</div>
        </div>
        <div class="row m-1 align-items-baseline">
          <div class="col p-0"><small>{{ $t('wz_case_form.wz_details.price_info.brutto') }}: </small></div>
          <div class="col p-0 text-end fs-5 fw-bold">{{ wz_case.costs.ALL.SUM.WITHOUT_PARTS_BACK.GROSS }}</div>
        </div>
      </div>
      <div class="card bg-ccs p-1 text-white" v-if="wz1_form.with_parts_back === true">
        <div class="row m-1 border-bottom">
          <div class="col p-0"><small>{{ $t('wz_case_form.wz_details.price_info.netto') }}: </small></div>
          <div class="col p-0 text-end">{{ wz_case.costs.ALL.SUM.WITH_PARTS_BACK.NET }}</div>
        </div>
        <div class="row m-1 align-items-baseline">
          <div class="col p-0"><small>{{ $t('wz_case_form.wz_details.price_info.brutto') }}: </small></div>
          <div class="col p-0 text-end fs-5 fw-bold">{{ wz_case.costs.ALL.SUM.WITH_PARTS_BACK.GROSS }}</div>
        </div>
      </div>
    </div>
    <div ref="wz1summaryRef"></div>
  </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "WzDetails",
  data() {
    return {
      hover_req_I_card: false,
      hover_req_II_card: false,
      hover_recommend_I_card: false,
      hover_recommend_II_card: false,
      hover_recommend_III_card: false,
      hover_accessories_card: [],
      hover_foil_card: [],
      estimate_items: {
        ITEMS: [],
      },
      estimate_summary: {
        NET: 0,
        GROSS: 0
      },
    }
  },
  mounted() {
    this.recalculateEstimateItems();
  },
  computed: {
    ...mapGetters('WzCase', {wz_case: 'wz_case'}),
    ...mapGetters('Wz1From', {wz1_form: 'wz1_form'}),
    selected() { return this.wz_case.costs.SELECTED },
    recommend_faults() {
      let faults = {};
      if(this.wz_case.costs.OPTIONS.RECOMMEND_I.PRESENT) {
        faults.RECOMMEND_I = this.uniqueFaults(this.wz_case.costs.OPTIONS.RECOMMEND_I.PARTS.ITEMS)
      }
      if(this.wz_case.costs.OPTIONS.RECOMMEND_II.PRESENT) {
        faults.RECOMMEND_II = this.uniqueFaults(this.wz_case.costs.OPTIONS.RECOMMEND_II.PARTS.ITEMS)
      }
      if(this.wz_case.costs.OPTIONS.RECOMMEND_III.PRESENT) {
        faults.RECOMMEND_III = this.uniqueFaults(this.wz_case.costs.OPTIONS.RECOMMEND_III.PARTS.ITEMS)
      }

      return faults;
    },
    wz_case_optional: () => {return true},
    formatPrice(){
      return (v)=>{
        return v.toFixed(2).toString().replace('.', ',');
      }
    },
    partName(){
      return (description)=>{
        if(description) {
          const split = description.split('/');
          if(split.length === 3) {
              return split[1];
          }
          return description;
        }
      }
    }
  },
  watch: {
    wz_case: {
      handler() {
        this.recalculateEstimateItems();
      },
      deep: true
    },
    selected: {
      handler() {
        this.recalculateEstimateItems();
      },
      deep: true
    }
  },
  methods: {
    ...mapActions('Wz1From', {updateWz1Form: 'updateWz1Form'}),
      hasRecommendations: function() {
        return this.wz_case.costs.OPTIONS.RECOMMEND_I.PRESENT || this.wz_case.costs.OPTIONS.RECOMMEND_II.PRESENT || this.wz_case.costs.OPTIONS.RECOMMEND_III.PRESENT
      },
      onRecommendSelect(option){
        this.wz_case.costs.SELECTED.RECOMMENDATIONS[option] = !this.wz_case.costs.SELECTED.RECOMMENDATIONS[option];
      },
      onFoilSelect: function (index) {
          if(this.wz_case.costs.SELECTED.FOIL_PROTECT !== index){
              this.wz_case.costs.SELECTED.FOIL_PROTECT = index
          }else{
              this.wz_case.costs.SELECTED.FOIL_PROTECT = null;
          }
      },
      onAdditionalSelect: function (index) {
          if(!this.wz_case.costs.SELECTED.ADDITIONAL.includes(index)){
              this.wz_case.costs.SELECTED.ADDITIONAL.push(index);
          }else{
              this.wz_case.costs.SELECTED.ADDITIONAL.splice(this.wz_case.costs.SELECTED.ADDITIONAL.indexOf(index), 1);
          }
      },
      uniqueFaults: function(items) {
        let rec_faults = [];
        items.forEach(function (item) {
          rec_faults.push(item.fault_code_desc);
        });
        return [...new Set(rec_faults)];
      },
      showPriceInfo: function() {
          return (
              this.wz_case.costs.SELECTED.RECOMMENDATIONS.RECOMMEND_I ||
              this.wz_case.costs.SELECTED.RECOMMENDATIONS.RECOMMEND_II ||
              this.wz_case.costs.SELECTED.RECOMMENDATIONS.RECOMMEND_III ||
              this.wz_case.costs.SELECTED.FOIL_PROTECT ||
              this.wz_case.costs.SELECTED.ADDITIONAL.length);
      },
      recalculateEstimateItems() {
        let selected = this.selected;
        let wz_costs_options = this.wz_case.costs.OPTIONS;
        let items = [];

        /** REQUIRED */
        let estimateVersion = selected['ESTIMATE_VERSION'];
        wz_costs_options[estimateVersion]['ALL']['ITEMS'].forEach(function (item) {
          items.push(item);
        });

        /** RECOMMENDATIONS */
        let rKeys = Object.keys(selected.RECOMMENDATIONS);
        let recommendations = rKeys.filter(function(key) {
          return selected.RECOMMENDATIONS[key]
        });

        recommendations.forEach( RECOMMENDATION => {
          wz_costs_options[RECOMMENDATION]['ALL']['ITEMS'].forEach(function (item) {
            items.push(item);
          });
        });

        /** ADDITIONAL */
        selected['ADDITIONAL'].forEach(function (rec_id) {
          for (const item of wz_costs_options['ADDITIONAL']) {
            if (item.rec_id === rec_id) items.push(item);
          }
        });

        /** FOIL PROTECT */
        if (selected['FOIL_PROTECT']) {
          for (const item of wz_costs_options['FOIL_PROTECT']) {
            if (item.rec_id === selected['FOIL_PROTECT']) items.push(item);
          }
        }

        /** Leave only the highest Service */
        let mainService = null;
        for (const [index, item] of items.entries()) {
          if (item.estimate_type_item_enum === 8) {
            if (!mainService) {
              mainService = {
                index: index,
                item: item
              };
            } else {
              if (mainService.item.gross_price > item.gross_price) {
                delete items[index];
              } else {
                /** New Main Service */
                delete items[mainService.index]
                mainService = {
                  index: index,
                  item: item
                };
              }
            }
          }
        }

        this.estimate_items = items;
        this.calculateEstimateSummary();

        this.updateWz1Form({
          key: 'wz_costs_selected',
          value: selected
        });

      },
      calculateEstimateSummary() {
        let estimate_summary = {
          NET: 0,
          GROSS: 0,
          NET_WITH_PARTS: this.wz_case.costs.ALL.WITH_PARTS_BACK_COSTS.NET,
          GROSS_WITH_PARTS: this.wz_case.costs.ALL.WITH_PARTS_BACK_COSTS.GROSS
        }

        this.estimate_items.forEach(function (item) {
          estimate_summary['NET'] = estimate_summary['NET'] + item.net_price;
          estimate_summary['GROSS'] = estimate_summary['GROSS'] + item.gross_price;
          estimate_summary['NET_WITH_PARTS'] = estimate_summary['NET_WITH_PARTS'] + item.net_price;
          estimate_summary['GROSS_WITH_PARTS'] = estimate_summary['GROSS_WITH_PARTS'] + item.gross_price;
        });

        this.wz_case.costs.ALL.SUM.WITH_PARTS_BACK.NET = (estimate_summary['NET_WITH_PARTS'] - this.wz_case.amount_paid_online).toFixed(2).toString().replace('.', ',');
        this.wz_case.costs.ALL.SUM.WITH_PARTS_BACK.GROSS = (estimate_summary['GROSS_WITH_PARTS'] - this.wz_case.amount_paid_online).toFixed(2).toString().replace('.', ',');
        this.wz_case.costs.ALL.SUM.WITHOUT_PARTS_BACK.NET = (estimate_summary['NET'] - this.wz_case.amount_paid_online ).toFixed(2).toString().replace('.', ',');
        this.wz_case.costs.ALL.SUM.WITHOUT_PARTS_BACK.GROSS = (estimate_summary['GROSS'] - this.wz_case.amount_paid_online).toFixed(2).toString().replace('.', ',');
    }
  }
}
</script>

<style scoped>

  .check-icon-indent {
    padding-left: 35px;
    text-indent: -35px
  }

  .fs-5-ccs-special {
    font-size: 1.2rem!important;
  }

  #additional-elements .form-switch input,
  #additional-elements .form-switch label {
    cursor: pointer;
  }
  #recommendations-table {
    border-collapse: separate;
    border-spacing: 0 10px;
    vertical-align: middle;
  }
  #recommendations-table tr {
    margin-bottom: 10px;
  }
  #recommendations-table td {
    padding: 0.25rem 0.5rem;
    //text-wrap: nowrap;
    margin: 0;
  }
  #recommendations-table td p {
    //text-wrap: wrap;
    margin: 0;
  }

  .ccs-card table {
    margin-bottom: 0.5rem!important;
  }

  .ccs-card .footer table {
    border-collapse: separate;
    border-spacing: 8px 0;
  }

  .ccs-card .footer table td {
    padding: 0!important;
  }


  .font-weight-bold{
    font-weight: bold;
  }

  @media (min-width: 576px) {
    .text-sm-justify {
      text-align: justify;
    }
  }
  @media (min-width: 768px) {
    .border-bottom-md-1 {
      border-bottom: 1px solid #0c6f5d !important;
    }
    .text-md-justify {
      text-align: justify;
    }
  }
</style>