<template>

  <div class="container mt-4 mb-4">
    <div class="row g-2 mt-2 card-group">
      <div class="col-xxl">
        <div class="card ccs-card-radius overflow-hidden border-4 w-100 h-100 shadow-sm ">
          <div class="card-body h-100">
            <h4 class="card-title">{{ $t('wz_case_form.no_wz_case_found.title') }}</h4>
            <hr class="hr-ccs">
            <p class="fs-5 text-justify">{{ $t('wz_case_form.no_wz_case_found.line_1') }}</p>
            <p class="fs-5 text-justify">{{ $t('wz_case_form.no_wz_case_found.line_2') }}</p>
            <p class="fs-5 text-justify">{{ $t('wz_case_form.no_wz_case_found.line_3') }}<br>
              <i>{{ $t('wz_case_form.no_wz_case_found.line_4') }}</i></p>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "NoWzCaseFound",
}
</script>

<style scoped>

</style>