<template>

  <div id="payment-rejected">

    <div id="order-number-section" class="row align-items-center">
      <div class="col-xxl">
        <div class="card ccs-card-radius overflow-hidden border-4 w-100 shadow-sm h-100" style="min-height: 425px;">

          <div v-if="wz_form_loading" class="card-body">
            <h4 class="card-title">{{ $t('payment.loading') }}</h4>
            <hr class="hr-ccs">
            <div class="mt-10 mb-4" style="margin-top: 120px;">
              <div id="loader"></div>
              <div class="loader-section section-left"></div>
              <div class="loader-section section-right"></div>
            </div>
          </div>

          <div v-if="!wz_form_loading && !wz_form_error" class="card-body">
            <h4 class="card-title">{{ $t('payment.rejected.title') }}</h4>
            <hr class="hr-ccs">
            <div class="row mb-3 mt-3">
              <div class="col-md-2"></div>
              <div class="col-md-8 mt-5">
                <p class="h5 text-center">{{ $t('payment.rejected.message_1') }}</p>
                <p class="h5 text-center">{{ $t('payment.rejected.message_2') }}</p>
                <br/>
                <p class="h5 text-center">
                  <app-icon-phone size="25" icon_color="#0c6f5d"/>&nbsp;&nbsp;{{ info.phone }}
                </p>
                <p class="h5 text-center">
                  <app-icon-email size="25" icon_color="#0c6f5d"/>&nbsp;&nbsp;
                  <a :href="`mailto:${info.email}`">{{ info.email }}</a>
                </p>
              </div>
              <div class="col-md-2"></div>
            </div>
          </div>

          <div v-if="wz_case_decision.disable_change_decision === false" class="card-footer bg-ccs-light">
            <div class="d-flex justify-content-around flex-wrap-reverse">
              <div class="p-2">
                <button class="btn btn-outline-ccs min-w-200 mt-2 mb-2" @click="clearDecision"
                        :disabled="wz_form_loading || wz_form_error">{{ $t('payment.rejected.btn_change_decision') }}
                </button>
              </div>
              <div class="p-2" :hidden="wz_case.amount_paid_online > 0">
                <button class="btn btn-ccs min-w-200 mt-2 mb-2" @click="changePaymentMethod('cash_on_delivery')"
                        :disabled="wz_form_loading || wz_form_error" >
                  {{ $t('payment.rejected.btn_change_payment_method') }}
                </button>
              </div>
              <div class="p-2">
                <button class="btn btn-ccs min-w-200 mt-2 mb-2" @click="changePaymentMethod('online_payment')"
                        :disabled="wz_form_loading || wz_form_error">{{ $t('payment.rejected.btn_go_to_payment') }}
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {Wz1PaymentProcess} from "@/mixins/Wz1PaymentProcess";

export default {
  name: "PaymentRejected",
  data() {
    return {
      info: {
        "phone": "22 726 38 10",
        "email": "dok@ccsonline.pl",
      }
    }
  },
  mixins: [Wz1PaymentProcess]
}
</script>

<style scoped>

</style>