<template>

  <div id="payment-finished">

    <div id="order-number-section" class="row align-items-center">
      <div class="col-xxl">
        <div class="card ccs-card-radius overflow-hidden border-4 w-100 shadow-sm h-100" style="min-height: 425px;">
          <div class="card-body">
            <h4 class="card-title">{{ $t('payment.finished.title') }}</h4>
            <hr class="hr-ccs">
            <div class="row mb-3 mt-3">
              <div class="col-md-2"></div>
              <div class="col-md-8 mt-5">
                <p class="h5 text-center">{{ $t('payment.finished.message_1') }}</p>
                <p class="h5 text-center">{{ $t('payment.finished.message_2') }}</p>
                <br/>
                <p class="h5 text-center"><app-icon-globe size="25" icon_color="#0c6f5d"/>&nbsp;&nbsp;
                  <a href="https://www.ccsonline.pl">www.ccsonline.pl</a>
                </p>
              </div>
              <div class="col-md-2"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>


</template>

<script>
export default {
  name: "PaymentFinished"
}
</script>

<style scoped>

</style>