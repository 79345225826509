<template>
  <div :id="`question_${questionObj.id}`" class="question card w-100 shadow-sm h-100">
    <h3 class="d-none d-md-block">{{ questionObj.question }}</h3>
    <h4 class="d-md-none">{{ questionObj.question }}</h4>
    <div class="select-type">
      <div v-for="(answer, index) in questionObj.answers" :key="index">
        <div v-if="questionObj.multiselect" class="row">
          <div class="form-check">
            <input :id="`a${questionObj.id}_${answer.id}`" v-model.lazy="questionObj.selectedAnswers"
                   :value="answer.id"
                   class="form-check-input"
                   type="checkbox"
                   @change="notifyQuestionUpdated(questionObj)">
            <label class="form-check-label" v-bind:for="`a${questionObj.id}_${answer.id}`">{{ answer.text }}</label>
          </div>
          <div class="answer-text-div">
              <textarea v-if='questionObj.selectedAnswers.includes(answer.id) && answer.type==="text"'
                        v-model.lazy.trim="answer.openText"
                        :placeholder="$t('questionnaire.desc_placeholder')"
                        class="answer-text"
                        maxlength="1000"/>
          </div>
        </div>
        <div v-else class="row">
          <div class="form-check">
            <input :id="`a${questionObj.id}_${answer.id}`" v-model.lazy="questionObj.selectedAnswers"
                   :name="`a${questionObj.id}`"
                   :value="answer.id"
                   class="form-check-input"
                   type="radio"
                   @change="notifyQuestionUpdated(questionObj)">
            <label class="form-check-label" v-bind:for="`a${questionObj.id}_${answer.id}`">{{ answer.text }}</label>
          </div>
          <div class="answer-text-div">
            <textarea v-if='questionObj.selectedAnswers === answer.id && answer.type==="text"'
                      v-model.lazy.trim="answer.openText"
                      :placeholder="$t('questionnaire.desc_placeholder')"
                      class="answer-text"
                      maxlength="1000"/>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ref} from "vue";

export default {
  name: "Question",
  props: ["question"],
  data() {
    const questionExtended = this.question;
    questionExtended.selectedAnswers = ref([]);
    return {
      questionObj: questionExtended
    }
  },

  emits: ['question-updated'],
  methods: {
    notifyQuestionUpdated: function () {
      this.$emit("question-updated", this.questionObj);
    }
  },
  setup() {
  }
}

</script>

<style scoped>
.question {
  
  margin-bottom: 20px;
  padding: 30px 30px;
}

.form-check-input {
  margin-top: 0.7em;
  width: 1.5em;
  height: 1.5em;
}

.form-check {
  min-height: 3rem;
  padding-left: 3em;
  background-color: #edeff1;
  margin-bottom: 5px;
  margin-top: 5px;
}

.form-check-label {
  margin-top: 0.45em;
  font-size: 21px;
  margin-left: 10px;
}

.answer-text {
  max-width: 100%;
  width: 100%;
  max-height: 200px;
  min-height: 30px;
  height: 100%;
}
.answer-text-div {
  padding: 0 0;
  margin-top: 5px
}
</style>