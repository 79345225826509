<template>

  <div>
    <div id="decision-form" class="h-100 h-100 animate__animated animate__fadeInLeft">
<!--    <div class="container p-0">-->
    <div class="m-auto mt-5" style="max-width: 1320px;">
      <div id="odp_row" class="row p-0 row-cols-1 row-cols-sm-2 row-cols-plussize-3  justify-content-center">
        <div class="odp_col mt-plussize-4 order-sm-2 order-plussize-1">
          <div id="odp_nie_card" class="odp_card card ccs-card-radius overflow-hidden border-4 shadow-sm mt-lg-5 mb-4 mh-350"
               :class="{ 'shadow-lg border-ccs': hover_answ_no_card }"
               @mouseover="hover_answ_no_card = true; hover_answ_yes_card = false"
               @mouseleave="hover_answ_no_card = false; hover_answ_yes_card = true">
            <div class="card-body bg-light text-center border-top border-4 border-white"
                 :class="{ 'bg-white': hover_answ_no_card }">
              <p class="fs-4 mt-2"><b>{{ $t('wz_case_form.decision_form.answ_no.title') }}</b></p><br/>
              <p class="fs-4 mb-3">
                {{ expertise_costs_gross_actual_amount_string }}&nbsp;{{
                  $t('wz_case_form.decision_form.currency')
                }}</p>
            </div>
            <div class="footer bg-ccs-light p-3 mh-300"
                 :class="{ 'bg-ccs-darker': hover_answ_no_card }">
              <div class="row justify-content-center">
                <div class="col text-center">
                  <button class="btn btn-outline-ccs btn-sem"
                          :class="{ 'btn-ccs': hover_answ_no_card }"
                          @click="confirmDecision('no')">
                    <b>{{ $t('wz_case_form.decision_form.button_select') }}</b>
                  </button>
                </div>
              </div>
              <div class="row justify-content-center">
                <div class="col-auto text-center">
                  {{ $t('wz_case_form.decision_form.answ_no.description') }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="odp_col order-sm-1 order-plussize-2 width-sm-51 width-plussize-33">
          <div id="odp_tak_card" class="odp_card card ccs-card-radius overflow-hidden border-4 shadow-sm mt-1 mb-4"
               :class="{ 'shadow-lg border-ccs': hover_answ_yes_card }"
               @mouseover="hover_answ_yes_card = true">
            <div class="card-body bg-light text-center border-4 border-white"
                 :class="{ 'bg-white': hover_answ_yes_card }">
              <p class="fs-4"><b>
                {{ $t('wz_case_form.decision_form.answ_yes.title') }}<br/>
                {{ $t('wz_case_form.decision_form.answ_yes.subtitle') }}
              </b></p>
              <p class="fs-4 mb-3" v-if="with_parts_back">
                {{ wz_case.costs.ALL.SUM.WITH_PARTS_BACK.GROSS }}&nbsp;{{
                  $t('wz_case_form.decision_form.currency')
                  }}
              </p>
              <p class="fs-4 mb-3" v-else>
                {{ wz_case.costs.ALL.SUM.WITHOUT_PARTS_BACK.GROSS }}&nbsp;{{
                  $t('wz_case_form.decision_form.currency')
                  }}
              </p>
            </div>
            <div class="footer bg-ccs-light p-3 mh-350"
                 :class="{ 'bg-ccs-darker': hover_answ_yes_card }">
              <div class="row justify-content-center">
                <div class="col text-center">
                  <button class="btn btn-outline-ccs btn-sem"
                          :class="{ 'btn-ccs': hover_answ_yes_card }"
                          @click="selectDecision('yes')">
                    <b>{{ $t('wz_case_form.decision_form.button_order_repair2') }}</b>
                  </button>
                </div>
              </div>
              <div class="row justify-content" v-if="wz_case.amount_paid_online > 0">
                <div class="col-auto text-center">
                  <p style="font-size: 12px;" class="text-danger">
                    {{ $t('wz_case_form.decision_form.answ_yes.amount_payed_online') }}
                  </p>
                </div>
              </div>
              <div class="row justify-content">
                <div class="col-auto text-center mt-0 mb-4">
                  {{ $t('wz_case_form.decision_form.answ_yes.description') }}
                </div>
              </div>
              <div class="row justify-content">
                <div class="col">
                  <div class="form-check">
                    <input class="form-check-input" type="radio" id="return_parts_1"
                           :value="false"
                           v-model="with_parts_back" @change="changePartsDeclaration(false)">
                    <label class="form-check-label" for="return_parts_1">
                      {{ $t('wz_case_form.decision_form.answ_yes.without_parts_back') }}
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" id="return_parts_2"
                           :value="true"
                           v-model="with_parts_back" @change="changePartsDeclaration(true)">
                    <label class="form-check-label" for="return_parts_2">
                      {{ $t('wz_case_form.decision_form.answ_yes.with_parts_back') }}
                    </label>
                  </div>
                </div>
              </div>
              <div class="row justify-content">
                <div class="col-auto text-center mt-4 mb-4">
                  <p style="font-size: 12px;">
                    {{ $t('wz_case_form.decision_form.answ_yes.description_parts_back') }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="odp_col mt-plussize-4 order-sm-3">
          <div id="odp_util_card" class="odp_card card ccs-card-radius overflow-hidden border-4 shadow-sm  mt-lg-5 mb-4 mh-350"
               :class="{ 'shadow-lg border-ccs': hover_answ_util_card }"
               @mouseover="hover_answ_util_card = true; hover_answ_yes_card = false"
               @mouseleave="hover_answ_util_card = false; hover_answ_yes_card = true">
            <div class="card-body bg-light text-center border-4 border-white"
                 :class="{ 'bg-white': hover_answ_util_card }">
              <p class="fs-4 mt-2"><b>{{ $t('wz_case_form.decision_form.answ_util.title') }}</b></p><br/>
              <p class="fs-4 mb-3">0,00&nbsp;{{ $t('wz_case_form.decision_form.currency') }}</p>
            </div>
            <div class="footer bg-ccs-light p-3 mh-300"
                 :class="{ 'bg-ccs-darker': hover_answ_util_card }">
              <div class="row justify-content-center">
                <div class="col text-center">
                  <button class="btn btn-outline-ccs btn-sem"
                          :class="{ 'btn-ccs': hover_answ_util_card }"
                          @click="confirmDecision('util')">
                    <b>{{ $t('wz_case_form.decision_form.button_select') }}</b>
                  </button>
                </div>
              </div>
              <div class="row justify-content-center">
                <div class="col-auto text-center">
                  {{ $t('wz_case_form.decision_form.answ_util.description_1') }}
                  <br><br>
                  {{ $t('wz_case_form.decision_form.answ_util.description_2') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
     </div>
      <app-confirmation-modal
          :message="confirm_message"
          ref="confirmationModal"
          @confirm="onConfirmDecision"
      />
  </div>

</template>

<script>

import {mapActions, mapGetters} from "vuex";

export default {
  name: "StepOneGeneral",
  data() {
    return {
      hover_answ_yes_card: true,
      hover_answ_no_card: false,
      hover_answ_util_card: false,
      with_parts_back: false,
      confirm_message: '',
      decision: ''
    }
  },
  computed: {
    ...mapGetters('WzCase', {wz_case: 'wz_case'}),
    ...mapGetters('Wz1From', {wz1_form: 'wz1_form'}),
    expertise_costs_gross_actual_amount_string() {
      let string = '0,00';
      if (this.wz_case.expertise_costs_gross_actual_amount > 0)
        string = this.wz_case.expertise_costs_gross_actual_amount_string
      return string;
    },
    estimate_costs_gross_actual_amount_with_parts_back_string() {
      let string = '0,00';
      if (this.wz_case.estimate_costs_gross_actual_amount_with_parts_back > 0)
        string = this.wz_case.estimate_costs_gross_actual_amount_with_parts_back_string
      return string;
    },
    estimate_costs_gross_actual_amount_without_parts_back_string() {
      let string = '0,00';
      if (this.wz_case.estimate_costs_gross_actual_amount_without_parts_back > 0)
        string = this.wz_case.estimate_costs_gross_actual_amount_without_parts_back_string
      return string;
    }
  },
  methods: {
    ...mapActions('Wz1From', {updateWz1Form: 'updateWz1Form'}),
    changePartsDeclaration: function (return_parts) {
      this.updateWz1Form({
        key: 'with_parts_back',
        value: return_parts
      });
    },
    confirmDecision: function($decision){
      if($decision === 'no') {
        this.confirm_message = this.$t('wz_case_form.decision_form.answ_no.confirmation');
      }
      if($decision === 'util') {
        this.confirm_message = this.$t('wz_case_form.decision_form.answ_util.confirmation');
      }
      this.decision = $decision;
      this.$refs.confirmationModal.show();
    },
    onConfirmDecision: function () {
      this.selectDecision(this.decision);
    },
    selectDecision: function (decision) {
      document.getElementById("decision-form").classList = "h-100 animate__animated animate__fadeOutLeft";
      setTimeout((function () {
        this.$emit('setDecision', decision);
      }).bind(this), 600);
    },
  }
}

</script>

<style scoped>

  @media (min-width: 576px) {
    .width-sm-51 {
      width: 51%;
    }
  }

  @media (min-width: 1980px) {
    .row-cols-plussize-3>* {
      flex: 0 0 auto;
      width: 33.3333333333%;
    }
    .width-plussize-33 {
      width: 33.3333333333%;
    }
    .order-plussize-1 {
      order: 1!important;
    }
    .order-plussize-2 {
      order: 2!important;
    }
    .mt-plussize-4 {
      margin-top: 1.5rem!important;
    }
  }

</style>