<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" fill="currentColor" class="bi bi-check"
       viewBox="0 0 24 24" :style="{color: icon_color}">
    <path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
  </svg>
</template>


<script>
export default {
  name: "Check",
  props: {
    size: {
      default: 15
    },
    icon_color: {
      default: '#ffffff'
    }
  }
}
</script>

<style scoped>

</style>