<template>

  <nav class="sticky-bottom navbar-light bg-white font-italic font-weight-light border-top-ccs navbar-main">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-xl">
          <span style="margin-left: 130px; font-size: small; ">
              <table>
                  <tbody>
                  <tr>
                      <td>
                          <b>{{ $t('footer.contact_title') }}</b>
                      </td>
                      <td>:</td>
                      <td>{{ $t('footer.contact_tel_line') }}</td>
                  </tr>
                  <tr>
                      <td colspan="2"></td>
                      <td>{{ $t('footer.contact_fax_line') }}</td>
                  </tr>
                  <tr>
                      <td colspan="2"></td>
                      <td>{{ $t('footer.contact_email_title') }}
                          <a :href="`mailto:dok@ccsonline.pl`">
                            dok@ccsonline.pl
                          </a>
                      </td>
                  </tr>
                  </tbody>
              </table>
          </span>
        </div>
        <div class="col-xl text-center">
              <span style="font-size: x-small; color: #c0c0c0; ">
                Cyfrowe Centrum Serwisowe S.A. © 2021. Wszystkie prawa zastrzeżone. <a
                  href="https://www.ccsonline.pl/CCS_polityka_prywatnosci.pdf" target="_blank">Polityka prywatności.</a> Korzystanie z serwisu oznacza akceptację <a
                  href="https://www.ccsonline.pl/images/stories/regulamin.pdf" target="_blank">Regulaminu</a>.
              </span>
        </div>
        <div class="col-xl text-center">
          <a class="navbar-brand" href="https://www.ccsonline.pl"><img
              src="../assets/CCS_logo_small.png"
              alt="Zaufaj profesjonalistom" border="0"></a>
        </div>
      </div>
    </div>
    <br>
  </nav>


</template>

<script>
export default {
  name: "Footer",
}
</script>

<style scoped>

.navbar-main {
  z-index: 1001;
}

</style>