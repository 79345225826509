import {createI18n} from 'vue-i18n'

import pl from '../lang/pl.json'

export default createI18n({
    locale: 'pl',
    messages: {
        pl
    }
})
