import {mapActions, mapGetters} from "vuex";

export const Wz1PaymentProcess = {

    computed: {
        ...mapGetters('WzCase', {
            wz_case: 'wz_case',
            user_ip: 'user_ip',
            wz_case_decision: 'wz_case_decision',
            wz_case_loading: 'loading'
        }),
        ...mapGetters('Wz1From', {wz1_form: 'wz1_form', wz_form_loading: 'loading', wz_form_error: 'error'}),
    },


    methods: {
        ...mapActions('Wz1From', {
            setWz1Form: 'setWz1Form',
            storeWzCaseDecision: 'storeWzCaseDecision',
            destroyWzCaseDecision: 'destroyWzCaseDecision'
        }),
        /**
         * Go to WzCase Payment provider page
         */
        goToPayment() {
            location.href = this.wz_case.payment_transaction_link;
        },
        /**
         * Clear decision, destroy decision
         */
        clearDecision() {
            let destroyStatus = this.destroyWzCaseDecision(this.wz_case.case_guid);
            const checkStatus = async () => {
                const status = await destroyStatus;
                if (status) {
                    location.reload();
                }
            };
            checkStatus();
        },
        /**
         * Change payment method - update decision.
         *
         * @param payment_method
         */
        changePaymentMethod(payment_method) {
            /** Step 1 - Set Wz1Form data */
            this.setWz1Form({
                case_guid: this.wz_case.case_guid,
                ip: this.user_ip,
                decision: this.wz_case_decision.decision,
                order_number: this.wz_case_decision.order_number,
                with_parts_back: this.wz_case_decision.with_parts_back,
                payment_type: payment_method, //'cash_on_delivery'
                payment_operator: payment_method === 'online_payment' ? 'P24' : null, //'P24'
                immediate_consent: this.wz_case_decision.immediate_consent,
            })
            /** Step 2 - Send decision to backend */
            let storeStatus = this.storeWzCaseDecision();
            const checkStatus = async () => {
                const status = await storeStatus;
                if (status) {
                    location.reload();
                }
            };
            checkStatus();
        }


    }


}