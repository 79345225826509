<template>

  <div class="row mt-2 card-group">
    <div class="col-xxl">
      <div class="card ccs-card-radius overflow-hidden border-4 w-100 h-100 shadow-sm error-card">
        <div class="card-body h-100">
          <h4 class="card-title">{{ $t('error.title') }}</h4>
          <hr class="hr-ccs">
          <p class="fs-4 text-center mt-5 mb-5 text-ccs">&nbsp;</p>
          <p v-if="message === 'default'" class="fs-5 text-center">{{ $t('error.line_1') }}</p>
          <p v-else class="fs-5 text-center">{{ message }}</p>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  name: "Error",
  props: {
    message: {
      type: String,
      default: "default",
    }
  }
}
</script>

<style scoped>

.error-card {
  min-height: 425px;
}

</style>