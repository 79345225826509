<template>

  <div id="wz1-step-three" class="h-100 animate__animated animate__fadeInRight">

    <div id="agreement-section" class="row align-items-center mt-2">
      <div class="col-xxl">
        <div class="card ccs-card-radius overflow-hidden border-4 w-100 shadow-sm h-100" style="min-height: 425px;">
          <div class="card-body">
            <h4 class="card-title">{{ $t('wz_case_form.wz1_step_three.title') }}</h4>
            <hr class="hr-ccs">
            <div class="row mb-3 mt-3">
              <div class="col-md">
                <div v-if="v_error" class="alert alert-danger" role="alert">
                  {{ v_validation_error }}
                </div>
                <div v-else class="alert">
                  &nbsp;
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" id="agree_1"
                         :value="true"
                         v-model="immediate_consent" @change="changeImmediateConsent">
                  <label class="form-check-label text-justify " for="agree_1">
                    <b>{{ $t('wz_case_form.wz1_step_three.check_agree') }}</b>&nbsp;
                    <a class="link-primary" data-bs-toggle="collapse" href="#immediate_consent_true_2" role="button"
                       aria-expanded="false" aria-controls="immediate_consent_true_2">[{{
                        $t('wz_case_form.btn_expand')
                      }}]</a>
                    <div class="collapse" id="immediate_consent_true_2">
                      {{ $t('wz_case_form.wz1_step_three.check_agree_more') }}
                    </div>
                  </label>
                </div>
                <div class="form-check mt-4 mb-4">
                  <input class="form-check-input" type="radio" id="agree_2"
                         :value="false"
                         v-model="immediate_consent" @change="changeImmediateConsent">
                  <label class="form-check-label" for="agree_2">
                    {{ $t('wz_case_form.wz1_step_three.check_disagree') }}
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="card-footer bg-ccs-light">
            <div class="d-flex justify-content-around flex-wrap-reverse">
              <div class="p-2">
                <button class="btn btn-outline-ccs min-w-200 mt-2 mb-2" @click="backToPrevStep">{{ $t('wz_case_form.btn_back') }}</button>
              </div>
              <div class="p-2">
                <button class="btn btn-ccs min-w-200 mt-2 mb-2" @click="submitStepThree">{{ $t('wz_case_form.btn_accept') }}</button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>

</template>

<script>


import {mapActions, mapGetters} from "vuex";
import {Wz1FormValidator} from "@/mixins/Wz1FormValidator";

export default {
  name: "StepThree",
  mixins: [Wz1FormValidator],
  data() {
    return {
      immediate_consent: null,
    }
  },
  computed: {
    ...mapGetters('WzCase', {wz_case: 'wz_case'}),
    ...mapGetters('Wz1From', {wz1_form: 'wz1_form'}),
  },
  methods: {
    ...mapActions('Wz1From', {updateWz1Form: 'updateWz1Form'}),
    changeImmediateConsent: function () {
      this.v_resetError();
      this.updateWz1Form({
        key: 'immediate_consent',
        value: this.immediate_consent
      });
    },
    backToPrevStep: function () {
      document.getElementById("wz1-step-three").classList = "h-100 animate__animated animate__fadeOutRight";
      setTimeout((function () {
        this.$emit('backToPrevStep');
      }).bind(this), 600);
    },
    submitStepThree: function () {
      if (this.v_validateImmediateConsent(this.immediate_consent)) {
        document.getElementById("wz1-step-three").classList = "h-100 animate__animated animate__fadeOutLeft";
        setTimeout((function () {
          this.$emit('submitStepThree');
        }).bind(this), 600);
      }
    }
  },
  mounted() {
    this.immediate_consent = this.wz1_form.immediate_consent;
  }
}
</script>

<style scoped>

.min-w-200 {
  min-width: 200px;
}


</style>