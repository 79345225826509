<template>

  <div :id="'decision-time-counter-'+version" class="h-100">
    <div class="card ccs-card-radius w-100 shadow-sm h-100">
      <div class="card-body h-100">
        <h4 class="card-title">{{ $t('wz_case_form.decision_time_counter.title') }}</h4>
        <hr class="hr-ccs">
        <div v-if="wz_case.date_to_decision.length > 0">
          <h4 class="card-title text-center mt-5 mb-5 text-ccs" :class="{ 'text-danger': timeLeft <= 0 }"><b>
            <span :id="'days-'+version">0</span>
            <span>&nbsp;{{ $t('wz_case_form.decision_time_counter.days_label') }}&nbsp;&nbsp;</span>
            <span :id="'hours-'+version" class="mr-0">00</span>
            <span class="ml-0">:</span>
            <span :id="'minutes-'+version">00</span>
            <span>:</span>
            <span :id="'seconds-'+version">00</span>
          </b></h4>
          <p class="fs-6 text-justify">
            {{ $t('wz_case_form.decision_time_counter.time_text_1') }}
          </p>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "DecisionTimeCounter",
  computed: {
    ...mapGetters('WzCase', {wz_case: 'wz_case'}),
  },
  props: {
    timer: Boolean,
    version: String
  },
  data() {
    return {
      timeLeft: 0,
    }
  },
  methods: {
    runTimer() {

        let endTime = new Date(this.wz_case.date_to_decision);
        endTime = (Date.parse(endTime) / 1000);
        let now = new Date();
        now = (Date.parse(now) / 1000);
        this.timeLeft = endTime - now;

        if(this.timeLeft > 0) {
          let days = Math.floor(this.timeLeft / 86400);
          let hours = Math.floor((this.timeLeft - (days * 86400)) / 3600);
          let minutes = Math.floor((this.timeLeft - (days * 86400) - (hours * 3600)) / 60);
          let seconds = Math.floor((this.timeLeft - (days * 86400) - (hours * 3600) - (minutes * 60)));
          if (hours < "10") {
            hours = "0" + hours;
          }
          if (minutes < "10") {
            minutes = "0" + minutes;
          }
          if (seconds < "10") {
            seconds = "0" + seconds;
          }
          document.getElementById("days-" + this.version).innerHTML = days.toString();
          document.getElementById("hours-" + this.version).innerHTML = hours.toString();
          document.getElementById("minutes-" + this.version).innerHTML = minutes.toString();
          document.getElementById("seconds-" + this.version).innerHTML = seconds.toString();
        }
        setTimeout(this.runTimer, 1000);
    }
  },
  mounted() {
    if (this.timer) {
      this.runTimer();
    }
  }
}
</script>

<style scoped>

</style>