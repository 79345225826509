<template>

  <div id="process-loader" class="h-100 animate__animated animate__fadeInRight">
    <div class="row align-items-center">
      <div class="col-xxl">
        <div class="card ccs-card-radius overflow-hidden border-4 w-100 shadow-sm h-100" style="min-height: 425px;">
          <div class="card-body">
            <h4 class="card-title">{{ message }}</h4>
            <hr class="hr-ccs">
            <div class="mt-10 mb-4">
              <div id="loader"></div>
              <div class="loader-section section-left"></div>
              <div class="loader-section section-right"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "ProcessLoader",
  props: [
    'message'
  ]
}
</script>

<style scoped>

.mt-10 {
  margin-top: 9rem;
}

</style>