export const Wz1FormValidator = {
    data() {
        return {
            v_error: false,
            v_is_valid: false,
            v_validation_error: null,
        }
    },
    methods: {
        v_validateOrderNumber: function (order_number) {
            /** ORDER NUMBER VALIDATION */
            if (order_number === null || order_number.trim() === "") {
                this.v_is_valid = false;
                this.v_error = true;
                this.v_validation_error = this.$t('validations.order_number_empty');
                return false;
            } else {
                if (order_number.length > 36) {
                    this.v_is_valid = false;
                    this.v_error = true;
                    this.v_validation_error = this.$t('validations.order_number_too_long');
                    return false;
                } else {
                    this.v_is_valid = true;
                    this.v_error = false;
                    this.v_validation_error = null;
                    return true;
                }
            }
        },
        v_validateImmediateConsent: function (immediate_consent) {
            if (immediate_consent === null) {
                this.v_is_valid = false;
                this.v_error = true;
                this.v_validation_error = this.$t('validations.immediate_consent_not_selected');
                return false;
            } else {
                this.v_is_valid = true;
                this.v_error = false;
                this.v_validation_error = null;
                return true;
            }
        },
        v_validatePaymentType: function (payment_type) {
            if (payment_type === null) {
                this.v_is_valid = false;
                this.v_error = true;
                this.v_validation_error = this.$t('validations.payment_type_not_selected');
                return false;
            } else {
                this.v_is_valid = true;
                this.v_error = false;
                this.v_validation_error = null;
                return true;
            }
        },
        v_resetError: function () {
            this.v_error = false;
            this.v_is_valid = false;
            this.v_validation_error = null;
        }
    }
}